import React, { PropsWithChildren } from 'react';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  FilterPanel,
  FilterBuilderPopup,
  Grouping,
  GroupPanel,
  Scrolling,
  RemoteOperations,
  Pager,
  Paging,
  SearchPanel,
  Selection,
} from 'devextreme-react/data-grid';

import ExportExcelSnackbar from './components/ExportExcelSnackbar';

import { GridProps, GridColumn } from './types';

import { useStyles } from './styles';

const DataGridDefaultSettings = {
  pageSize: 25,
  allowedPageSizes: [10, 25, 50, 100, 250],
};

export const renderColumns = (columns: GridColumn[]) => {
  return columns.map((col) => {
    if (col.component) {
      return col.component;
    }
    const { validations, ...colData } = col;
    return (
      <Column key={col.dataField} {...colData}>
        {validations}
      </Column>
    );
  });
};

const Grid = React.forwardRef<DataGrid, PropsWithChildren<GridProps>>(
  ({ dataSource, gridName, columns, gridOptions, children, handlersRef, ...htmlOptions }, ref) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const {
      grouping = { contextMenuEnabled: true },
      groupPanel = { visible: true },
      filterRow = { visible: true },
      searchPanel = { visible: true },
      editing,
      selection = { mode: 'single' },
      ...safeGridOptions
    } = gridOptions ?? {};

    return (
      <DataGrid
        {...htmlOptions}
        className={classnames(classes.grid, {
          [classes.grouping]: groupPanel?.visible ?? false,
        })}
        dataSource={dataSource}
        wordWrapEnabled
        hoverStateEnabled
        showBorders
        allowColumnResizing
        columnResizingMode="widget"
        columnAutoWidth
        ref={ref}
        onExporting={() => {
          enqueueSnackbar(`Preparing ${gridName ?? ''} data to export...`, {
            key: `export-excel-${gridName ?? ''}`,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            persist: true,
            preventDuplicate: true,
            content: (key, message) => {
              return <ExportExcelSnackbar key={key} message={message} />;
            },
          });
        }}
        onExported={() => {
          closeSnackbar(`export-excel-${gridName ?? ''}`);
        }}
        {...safeGridOptions}
      >
        <Selection {...selection} />
        <Scrolling mode="standard" />
        <Grouping autoExpandAll={false} {...grouping} />
        <RemoteOperations sorting paging />
        <Paging enabled defaultPageSize={DataGridDefaultSettings.pageSize} />
        <Pager
          visible
          allowedPageSizes={DataGridDefaultSettings.allowedPageSizes}
          showInfo
          showPageSizeSelector
          showNavigationButtons={false}
        />
        {groupPanel ? <GroupPanel {...groupPanel} /> : null}
        {columns && renderColumns(columns)}
        {children}
        <FilterRow {...filterRow} />
        <SearchPanel {...searchPanel} />
        <FilterPanel {...filterRow} />
        <FilterBuilderPopup />
        <HeaderFilter {...filterRow} />
      </DataGrid>
    );
  },
);

export default Grid;
