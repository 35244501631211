import * as React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Grid, Paper, Table as MuiTable, TableContainer } from '@material-ui/core';
import TableHead from './TableHead';
import TableBody from './TableBody';

export interface TableColumnProps {
  field: string;
  name: string;
  style?: React.CSSProperties;
  cellRender?: (cell: TableRowProps, index: number) => React.ReactNode;
}

export interface TableRowProps {
  [x: string]: any;
  style?: React.CSSProperties;
}

export interface TableProps {
  columns: TableColumnProps[];
  rows: TableRowProps[];
  style?: React.CSSProperties;
}

const Table: React.FC<TableProps> = ({ columns, rows, style }) => {
  if (!rows?.length) {
    return (
      <Grid container>
        <Alert style={{ flex: 1 }} severity="info">
          There is no data to show
        </Alert>
      </Grid>
    );
  }
  return (
    <TableContainer component={Paper}>
      <MuiTable style={style}>
        <TableHead columns={columns} />
        <TableBody columns={columns} rows={rows} />
      </MuiTable>
    </TableContainer>
  );
};

export default React.memo(Table);
