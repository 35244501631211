import React, { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, InputAdornment, CircularProgress, Collapse, IconButton } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import Input from 'modules/shared/components/Input';
import { authSelectors, authThunks, authActions } from 'modules/auth';
import { history } from 'configure/browserHistory';

import msalInstance, { oauthEnabled } from 'configure/azure-ad-b2c';

import { noop } from 'modules/shared/utils';
import { useStyles } from './styles';

const LoginUi = () => {
  const classes = useStyles();
  const formRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const isLoading = useSelector(authSelectors.getIsLoading);
  const error = useSelector(authSelectors.getAuthStateError);
  const dispatch = useDispatch();

  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [ssoLoading, setSsoLoading] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(authActions.clearLogin());
    };
  }, [dispatch]);

  const login = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(authThunks.login(user));
    },
    [dispatch, user],
  );

  const handleChange = useCallback(({ target }) => {
    const { id, value } = target;

    setUser((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);

  const [animation, setAnimation] = useState('cardAnimation');
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation('');
    }, 50); // since modal opens on route change we need to add a little delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const goToHomeIfClickedOnOverlay = useCallback(({ target }) => {
    if (!formRef.current?.contains(target)) {
      history.replace('/home');
    }
  }, []);

  const goToRegister = useCallback(() => {
    history.replace('/register');
  }, []);

  const onForgotLinkClick = useCallback(() => {
    history.replace('/forgot-credentials');
  }, []);

  const startSSO = useCallback(() => {
    setSsoLoading(true);
    msalInstance.loginRedirect();
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      className={classes.container}
      onClick={goToHomeIfClickedOnOverlay}
    >
      <Grid container justifyContent="center" className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <form onSubmit={login}>
            <div ref={formRef} className={classnames(classes.card, classes[animation])}>
              <div className={classes.cardHeader}>
                <h2 className={classes.cardTitle}>Log-In</h2>
              </div>
              <div className={classes.cardBody}>
                {oauthEnabled && (
                  <div className={classes.ssoButtonContainer}>
                    <Button fullWidth color="primary" variant="contained" onClick={ssoLoading ? noop : startSSO}>
                      {ssoLoading ? <CircularProgress color="inherit" size={20} /> : 'Login'}
                    </Button>
                  </div>
                )}
                {!oauthEnabled && (
                  <>
                    <Input
                      error={!!error}
                      labelText="Email Address"
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: 'email',
                        value: user.email,
                        required: true,
                        onChange: handleChange,
                      }}
                    />
                    <Input
                      error={!!error}
                      labelText="Password"
                      id="password"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: showPassword ? 'text' : 'password',
                        value: user.password,
                        required: true,
                        onChange: handleChange,
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Collapse in={!!error}>
                      <Alert severity="error">{error}</Alert>
                    </Collapse>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <Button fullWidth color="primary" onClick={goToRegister}>
                          Register
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Button fullWidth color="primary" type="submit">
                          {isLoading ? <CircularProgress size={20} /> : "Let's go"}
                        </Button>
                      </Grid>
                    </Grid>
                    <div className={classes.control}>
                      <Button onClick={onForgotLinkClick}>Forgot credentials?</Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </form>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(LoginUi);
