import { memo, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { chartTooltipStyle } from 'modules/shared/styles/global-classes';
import { useStyles } from './styles';

const Bar = ({ data }) => {
  const classes = useStyles();

  const barData = useMemo(
    () =>
      data.children.map((it) => ({
        source: it.name,
        count: parseInt(it.value, 10),
      })),
    [data],
  );

  return (
    <div className={classes.chartContainer}>
      <div className={classes.chartWrapper}>
        <ResponsiveBar
          data={barData}
          keys={['count']}
          indexBy="source"
          margin={{ top: 10, right: 20, bottom: 25, left: 110 }}
          groupMode="grouped"
          layout="horizontal"
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          enableLabel={false}
          animate
          motionStiffness={90}
          motionDamping={15}
          theme={{ tooltip: { container: chartTooltipStyle } }}
          enableGridY={false}
          enableGridX
          padding={0.6}
          colors="hsl(238, 100%, 47%)"
          borderRadius={2}
          axisLeft={{
            format: (v) => {
              return v.length > 10 ? (
                <tspan>
                  {`${v.substring(0, 10)}...`}
                  <title>{v}</title>
                </tspan>
              ) : (
                v
              );
            },
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          tooltip={({ value, indexValue }) => {
            return (
              <div>
                {indexValue}: {value}
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default memo(Bar);
