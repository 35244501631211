import * as React from 'react';
import { getLegendsMiddleware } from '../middlewares/legend';
import { LegendType } from '../enums/legend';
import { Color } from '../interfaces/color';
import { Legend } from '../interfaces/legend';

const emptyColor: Color = {
  id: '',
  name: '',
  color: 'rgb(226 226 226)',
};
export const useLegends = (categoryId: string, type?: LegendType) => {
  const [legends, setLegends] = React.useState<Legend[]>([]);

  React.useEffect(() => {
    if (!categoryId) {
      return;
    }
    (async () => {
      const { isSuccess, data } = await getLegendsMiddleware(categoryId, type);
      if (isSuccess) {
        setLegends([...data, { id: '', categoryId: '', name: 'No Project Data', color: emptyColor }]);
      }
    })();
  }, [categoryId, type]);

  return { legends, setLegends };
};
