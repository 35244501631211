import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { LOAD_MAPPINGS_HISTORY_ITEM } from '../gql';
import { Step } from '../types';

const parseConfig = (
  config: string,
): {
  selectedStep: string;
  steps: Step[];
} => {
  if (config.length) {
    const { steps, selectedStep } = JSON.parse(config);

    return {
      steps,
      selectedStep,
    };
  }

  return {
    steps: [],
    selectedStep: '',
  };
};

export const useLoadHistoryItemConfig = ({ connection, projectId, historyId }) => {
  const { loading, data } = useQuery(LOAD_MAPPINGS_HISTORY_ITEM, {
    variables: {
      connection,
      projectId,
      historyId,
    },
    skip: !connection || !projectId || !historyId,
    notifyOnNetworkStatusChange: true,
  });

  const { selectedStep, steps } = useMemo(() => parseConfig(data?.mappingsHistoryItem.config || ''), [data]);

  return {
    loading,
    selectedStep,
    steps,
  };
};
