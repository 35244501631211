import { CircularProgress, Typography } from '@mui/material';
import { Button } from '@material-ui/core';
import { format } from 'date-fns';
import { Dialog, Title, Content } from 'modules/shared/components/Dialog';
import { memo, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Select from 'modules/shared/components/Select2';
import { LayoutRef } from 'modules/shared/components/DraggableLayout';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { noop } from 'modules/shared/utils';
import { PreviewContext } from 'modules/shared/components/DraggableLayout/context/PreviewContext';
import { useToggle } from 'modules/shared/hooks/base';
import classNames from 'classnames';
import { HistoryContext, HistoryHandlersContext } from '../history-context/context';
import { useStyles } from './styles';
import { useLoadHistoryItemConfig } from '../hooks/useLoadHistoryItemConfig';
import { WizzardContext, WizzardHandlersContext } from '../wizzard-context/context';
import StepperUi from '../stepper-ui';
import Builder from '../wizzard-ui/Builder';
import { usePublishSteps } from '../hooks/usePublishSteps';

const MODAL_ID = 'mappings-history-modal';

const insidePreviewProps = { insidePreview: true };

const HistoryModalUI = () => {
  const classes = useStyles();
  const { templateId } = useContext(WizzardContext);
  const { setState } = useContext(WizzardHandlersContext);
  const { historyItems, historyModalOpen } = useContext(HistoryContext);
  const { closeHistoryModal } = useContext(HistoryHandlersContext);
  const [currentSelectedStep, setCurrentSelectedStep] = useState('');

  const { connection, projectId } = useProjectInfoFromSearch();
  const { publish, publishing } = usePublishSteps({ connection, projectId });

  const [selected, setSelected] = useState('');

  const { steps, selectedStep, loading } = useLoadHistoryItemConfig({ connection, projectId, historyId: selected });

  const layoutRef = useRef<LayoutRef>({} as LayoutRef);

  const handlePublish = useCallback(async () => {
    setSelected('');
    const createdStep = await publish(steps, selectedStep, templateId);

    setState(steps, selectedStep);
    setSelected(createdStep?.data.publishMappings.historyId || '');
  }, [publish, steps, selectedStep, templateId, setState]);

  useEffect(() => {
    if (historyModalOpen && selectedStep) {
      setCurrentSelectedStep(selectedStep);
    }
  }, [historyModalOpen, selectedStep]);

  useEffect(() => {
    if (!historyModalOpen) {
      setSelected('');
      setCurrentSelectedStep('');
    }

    if (historyModalOpen && historyItems.length) {
      setSelected(historyItems[0].historyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyModalOpen]);

  const [isBlendConfigModalOpen, { activate: openBlendConfig, deactivate: closeBlendConfig }] = useToggle();

  const handlers = useMemo(
    () => ({
      createStep: noop,
      deleteStep: noop,
      selectStep: setCurrentSelectedStep,
      updateShape: noop,
      setupInputShape: noop,
      openImportSourceModal: noop,
      closeImportSourceModal: noop,
      openBlendConfigModal: openBlendConfig,
      closeBlendConfigModal: closeBlendConfig,

      openUpdateTransformationModal: noop,
      closeUpdateTransformationModal: noop,
      addTransformation: noop,
      updateTransformation: noop,
      deleteTransformation: noop,
      resetInputCard: noop,
      updateDraggableItem: noop,
      createConnection: noop,
      deleteConnectionChain: noop,
      updateStepConfig: noop,
      openAddColumnTypeModal: noop,
      closeAddColumnTypeModal: noop,
      resetState: noop,
      setState: noop,
      openEditDefaultValueModal: noop,
      closeEditDefaultValueModal: noop,
      updateDefaultValue: noop,

      addCombineColumn: noop,
      updateCombineColumn: noop,
      deleteCombineColumn: noop,
      openUpdateCombineModalModal: noop,
      closeUpdateCombineModalModal: noop,
    }),
    [closeBlendConfig, openBlendConfig],
  );

  const values = useMemo(
    () => ({
      baseInfoLoading: false,
      isAddColumnTypeModalOpen: false,
      layoutRef,
      connection,
      projectId,

      templateId: '',
      selectedStep: currentSelectedStep,
      steps,
      stepData: steps.find((s) => s.id === currentSelectedStep),

      isBlendConfigModalOpen,
      isImportSourceModalOpen: false,
      isUpdateTransformationModalOpen: false,
      selectedImportShape: '',
      editableTansformation: '',
      editableCombineColumn: '',
      draggableItem: {
        parentId: '',
        title: '',
      },
      currentAddColumn: '',
      currentDefaultValueConfig: { col: '', shapeId: '' },
      isEditDefaultValueModalOpen: false,
      isDraft: false,
      isUpdateCombineColumnOpen: false,
    }),
    [connection, currentSelectedStep, isBlendConfigModalOpen, projectId, steps],
  );

  return (
    <Dialog fullWidth fullScreen onClose={closeHistoryModal} aria-labelledby={MODAL_ID} open={historyModalOpen}>
      <Title id={`${MODAL_ID}-title`} classTypo={classes.flex} onClose={closeHistoryModal}>
        <div className={classes.header}>
          <Typography variant="h6">Preview History</Typography>
          <div style={{ display: 'flex', gap: 20 }}>
            <Button variant="contained" color="primary" disabled={!selected || publishing} onClick={handlePublish}>
              Rollback
            </Button>
            <Select
              id="history-preview-data-selector"
              value={[selected]}
              options={historyItems.map((i) => ({
                value: i.historyId,
                label: `${format(new Date(i.createdAt), 'yyyy-MM-dd HH:mm')} (${i.publishedBy || 'Unknown'})`,
              }))}
              onChange={(val) => setSelected(val[0])}
              className={classes.selection}
              placeholder="Choose date time..."
              valueContainer={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 147px)',
                fontWeight: 400,
                fontSize: 14,
              }}
              multiValueLabel={{ maxHeight: 115, overflowY: 'scroll' }}
            />
          </div>
        </div>
      </Title>
      <Content className={classes.page}>
        {selected && selectedStep && !loading && !publishing && (
          <WizzardHandlersContext.Provider value={handlers}>
            <WizzardContext.Provider value={values}>
              <PreviewContext.Provider value={insidePreviewProps}>
                <div className={classes.container}>
                  <div className={classes.stepper}>
                    <StepperUi />
                  </div>
                  <div className={classes.content}>
                    <Builder />
                  </div>
                </div>
              </PreviewContext.Provider>
            </WizzardContext.Provider>
          </WizzardHandlersContext.Provider>
        )}

        {(loading || publishing) && (
          <div className={classNames(classes.container, classes.center)}>
            <CircularProgress size={50} />
          </div>
        )}
      </Content>
    </Dialog>
  );
};

export default memo(HistoryModalUI);
