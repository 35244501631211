import classnames from 'classnames';
import { useStyles } from './styles';

export type InfoItemProps = {
  className?: string;
  title: string;
  description: string;
  image: string;
};

const InfoItem = ({ className, image, title, description }: InfoItemProps) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.item, className)}>
      <div className={classes.row}>
        <img className={classes.img} src={image} alt={title} />
        <div className={classes.title}>{title}</div>
      </div>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

InfoItem.defaultProps = {
  className: '',
};

export default InfoItem;
