import { State } from '../types';
import getState from './getState';

export const getAuthState = (state): State => {
  return getState(state).state;
};

export const getAuthStateError = (state) => {
  return getAuthState(state).error;
};

export const getAuthStateRegisterErrors = (state) => {
  return getAuthState(state).registerErrors;
};
