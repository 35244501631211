import { noop } from 'modules/shared/utils';
import { createContext } from 'react';
import { HistoryItem, Step } from '../types';

export const HistoryContext = createContext<{
  historyModalOpen: boolean;
  itemsLoading: boolean;
  historyItems: HistoryItem[];
  latestHistoryItem: {
    selectedStep: string;
    steps: Step[];
  };
}>({
  historyModalOpen: false,
  itemsLoading: false,
  historyItems: [],
  latestHistoryItem: {
    selectedStep: '',
    steps: [],
  },
});

export const HistoryHandlersContext = createContext<{
  openHistoryModal: () => void;
  closeHistoryModal: () => void;
}>({
  openHistoryModal: noop,
  closeHistoryModal: noop,
});
