import axios from 'axios';
import { Note } from '../interfaces/note';

export const getNotesMiddleware = async (
  categoryId: string,
): Promise<{
  isSuccess: boolean;
  data: Note | null;
}> => {
  try {
    const { data } = await axios.get(`notes/categories/${categoryId}`);
    return { isSuccess: true, data: data.data };
  } catch (error) {
    return { isSuccess: false, data: null };
  }
};

export const saveNotesMiddleware = async (
  body: Note,
  id?: string,
): Promise<{
  isSuccess: boolean;
  data: Note | null;
  message: any;
}> => {
  try {
    const { data } = await (id ? axios.put(`notes/${id}`, body) : axios.post(`notes`, body));
    return { isSuccess: true, data: data.data, message: data.message };
  } catch (error: any) {
    return {
      isSuccess: false,
      data: null,
      message: error?.message || 'Something wrong when update note!',
    };
  }
};
