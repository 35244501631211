import { gql } from '@apollo/client';

export const LOAD_COLUMNS = gql`
  query hdfsFileColumns(
    $connection: String!
    $projectId: String!
    $category: String!
    $dataTemplate: String!
    $file: String!
  ) {
    hdfsFileColumns(
      connection: $connection
      project_id: $projectId
      category: $category
      data_template: $dataTemplate
      file: $file
    ) {
      columns
    }
  }
`;

export const LOAD_MAPPINGS_HISTORY = gql`
  query mappingsHistory($connection: String!, $projectId: String!, $dataTemplate: String!) {
    mappingsHistory(connection: $connection, project_id: $projectId, data_template: $dataTemplate) {
      historyId
      createdAt
      fkDataTemplate
      publishedBy
    }
  }
`;

export const LOAD_MAPPINGS_HISTORY_ITEM = gql`
  query mappingsHistoryItem($connection: String!, $projectId: String!, $historyId: String!) {
    mappingsHistoryItem(connection: $connection, project_id: $projectId, history_id: $historyId) {
      config
    }
  }
`;
