import loadable from '@loadable/component';
import { CircularProgress } from '@material-ui/core';

import Login from './Login';
import Register from './Register';
import ForgotCredentials from './ForgotCredentials';
import ChangePassword from './ChangePassword';
import Home from './Home';
import Dashboard from './Dashboard';
import Users from './Users';
import CapitalProjects from './CapitalProjects';
import Workspace from './Workspace';
import NeoSpaces from './Neo-Spaces';
import DigitalThreads from './DigitalThreads';
import DataSpaces from './DataSpaces';
import DataStores from './DataStores';
import NewDashboard from './NewDashboard';
import SSO from './SSO';

const Loader = () => {
  return (
    <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress size={50} />
    </div>
  );
};

const DataValidations = loadable(() => import(/* webpackChunkName: "DataValidations" */ './DataValidations'), {
  fallback: <Loader />,
});
const FlowControl = loadable(() => import(/* webpackChunkName: "FlowControl" */ './FlowControl'), {
  fallback: <Loader />,
});
const ExecutionLog = loadable(() => import(/* webpackChunkName: "ExecutionLog" */ './ExecutionLog'), {
  fallback: <Loader />,
});

const Integrations = loadable(() => import(/* webpackChunkName: "Integrations" */ './Integrations'), {
  fallback: <Loader />,
});
const Applications = loadable(() => import(/* webpackChunkName: "Applications" */ './Applications'), {
  fallback: <Loader />,
});
const Assets = loadable(() => import(/* webpackChunkName: "Assets" */ './Assets'), {
  fallback: <Loader />,
});
const Stakeholders = loadable(() => import(/* webpackChunkName: "Stakeholders" */ './Stakeholders'), {
  fallback: <Loader />,
});
const FlowSolutions = loadable(() => import(/* webpackChunkName: "FlowSolutions" */ './FlowSolutions'), {
  fallback: <Loader />,
});
const EditFlowSolutionBoard = loadable(
  () => import(/* webpackChunkName: "EditFlowSolutionBoard" */ './EditFlowSolutionBoard'),
  {
    fallback: <Loader />,
  },
);
const NeoSpaceTemplates = loadable(() => import(/* webpackChunkName: "NeoSpaceTemplates" */ './NeoSpaceTemplates'), {
  fallback: <Loader />,
});

const Project = loadable(() => import(/* webpackChunkName: "Project" */ './Project'), {
  fallback: <Loader />,
});
const NeoBoard = loadable(() => import(/* webpackChunkName: "NeoBoard" */ './NeoBoard'), {
  fallback: <Loader />,
});
const TimeTravel = loadable(() => import(/* webpackChunkName: "TimeTravel" */ './TimeTravel'), {
  fallback: <Loader />,
});
const ConfigTables = loadable(() => import(/* webpackChunkName: "ConfigTables" */ './ConfigTables'), {
  fallback: <Loader />,
});

const ConnectionManager = loadable(() => import(/* webpackChunkName: "ConnectionManager" */ './ConnectionManager'), {
  fallback: <Loader />,
});

export {
  Login,
  Register,
  ForgotCredentials,
  ChangePassword,
  Dashboard,
  Home,
  Users,
  FlowControl,
  DataValidations,
  ExecutionLog,
  CapitalProjects,
  Integrations,
  Applications,
  Workspace,
  Assets,
  Stakeholders,
  NeoSpaces,
  FlowSolutions,
  NeoSpaceTemplates,
  DigitalThreads,
  Project,
  DataSpaces,
  NeoBoard,
  TimeTravel,
  DataStores,
  ConfigTables,
  EditFlowSolutionBoard,
  NewDashboard,
  ConnectionManager,
  SSO,
};
