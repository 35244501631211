import { memo, useCallback } from 'react';
import { Button, Grid, CircularProgress, Collapse, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import Input from 'modules/shared/components/Input';

import { useChangePassword } from './hooks/useChangePassword';

import { useStyles } from './styles';

type RouterParams = {
  token: string;
};

const ChangePasswordUI = () => {
  const classes = useStyles();
  const { token } = useParams<RouterParams>();
  const { changePassword, loading, error } = useChangePassword();

  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const data = new FormData(event.target);

      changePassword(token, data.get('password') as string, data.get('confirm-password') as string);
    },
    [changePassword, token],
  );

  return (
    <div role="button" tabIndex={0} onKeyPress={() => {}} className={classes.container}>
      <Grid container justify="center" className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <Box component="form" className={classnames(classes.card)} onSubmit={onSubmit}>
            <div className={classes.cardHeader}>
              <h2 className={classes.cardTitle}>Change Password</h2>
            </div>
            <div className={classes.cardBody}>
              <Input
                error={!!error}
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'password',
                  name: 'password',
                  required: true,
                }}
              />
              <Input
                error={!!error}
                labelText="Confirm Password"
                id="confirm-password"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'password',
                  name: 'confirm-password',
                  required: true,
                }}
              />
              <Collapse in={!!error}>
                <Alert severity="error" className={classes.error}>
                  {error}
                </Alert>
              </Collapse>
              <Grid container spacing={1} className={classes.cardControls}>
                <Button fullWidth color="primary" type="submit">
                  {loading ? <CircularProgress size={20} /> : 'Change password'}
                </Button>
              </Grid>
            </div>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(ChangePasswordUI);
