import { appThunks } from 'modules/app';
import { Auth } from 'modules/rest';
import { LoginData } from 'modules/rest/api/auth';

import Storage from 'modules/storage';

import * as actions from '../actions';

export const login = (userData: LoginData) => async (dispatch) => {
  dispatch(actions.authLoginStart());

  try {
    const {
      data: { accessToken, refreshToken, user },
    } = await Auth.login(userData);

    Storage.setAccessToken(accessToken);
    Storage.setRefreshToken(refreshToken);

    await dispatch(appThunks.preloadData());

    dispatch(
      actions.authLoginSuccess({
        id: user.id,
        username: user.username,
        email: user.email,
        role: user.role,
        connections: user.connections,
        adminConnections: user.adminConnections,
      }),
    );
  } catch (error) {
    dispatch(
      // @ts-ignore
      actions.authLoginFailure(error.response?.data?.message || 'Something went wrong, please try again later.'),
    );
  }
};
