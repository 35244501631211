import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import Select from 'modules/shared/components/Select';
import { useContext, useEffect, useState } from 'react';
import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import BodyFields from './BodyFields';
import { useBodyFieldsHook } from './hooks/useBodyFieldsHook';
import { useFlowExecuteCustomizeHook } from './hooks/useFlowExecuteCustomizeHook';
import { useHeadersHook } from './hooks/useHeadersHook';
import { useStyles } from './styles';
import { cleanFields } from './utils';

const MODAL_ID = 'flow-execute-customize-modal';
const FlowExecuteCustomizeModalUI = ({ open, onClose, flowsToExecute, selectedFlows }) => {
  const [step, setStep] = useState(0);
  const [flowSelection, setFlowSelection] = useState('');
  const classes = useStyles();

  const { boardId } = useContext(StepExecuteContext);
  const { connection } = useProjectInfoFromSearch();

  const { flowExecuteCustomize, create, update, loading, refetch } = useFlowExecuteCustomizeHook(
    flowSelection,
    boardId,
    connection,
  );
  const flowExecute = flowSelection ? flowsToExecute.find((el) => el.flowId === flowSelection) : {};

  const { headers, setHeaders } = useHeadersHook(flowExecute, flowExecuteCustomize);
  const { bodyFields, setBodyFields } = useBodyFieldsHook(flowExecute, flowExecuteCustomize);

  const newFlowsToExecute = flowsToExecute
    .map((flow) => {
      if (selectedFlows.includes(flow.flowId)) {
        return { label: `${flow.flowName} (${flow.isActive ? 'Active' : 'Inactive'})`, value: flow.flowId };
      }
      return undefined;
    })
    .filter((el) => el);

  useEffect(() => {
    if (open) {
      setStep(0);
      setFlowSelection('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleBack = () => {
    if (step === 0) {
      onClose();
      return;
    }
    setStep(0);
  };

  const handleNext = () => {
    if (step === 0) {
      refetch();
      setStep(1);
      return;
    }
    if (flowExecuteCustomize) {
      const dataUpdate = {
        flowCustomize: {
          fkFlowId: flowExecuteCustomize.fkFlowId,
          fkBoardId: boardId,
          headers: Object.keys(headers).length ? JSON.stringify(cleanFields(headers)) : '',
          bodyFields: Object.keys(bodyFields).length ? JSON.stringify(cleanFields(bodyFields)) : '',
        },
        flowCustomizeId: flowExecuteCustomize.id,
        connectionName: connection,
        title: flowExecute.flowName,
      };
      update(dataUpdate, onClose);
      return;
    }
    const dataInsert = {
      flowCustomize: {
        fkFlowId: flowSelection,
        fkBoardId: boardId,
        headers: Object.keys(headers).length ? JSON.stringify(cleanFields(headers)) : '',
        bodyFields: Object.keys(bodyFields).length ? JSON.stringify(cleanFields(bodyFields)) : '',
      },
      connectionName: connection,
      title: flowExecute.flowName,
    };
    create(dataInsert, onClose);
  };
  const handleFieldChange = (target: 'headers' | 'bodyFields') => (id, name, value) => {
    if (target === 'headers') {
      setHeaders({
        ...headers,
        [id]: {
          ...headers[id],
          [name]: value,
        },
      });
      return;
    }

    setBodyFields({
      ...bodyFields,
      [id]: {
        ...bodyFields[id],
        [name]: value,
      },
    });
  };

  const handleFieldDelete = (target: 'headers' | 'bodyFields') => (id) => {
    if (target === 'headers') {
      const headersCopy = { ...headers };
      delete headersCopy[id];
      setHeaders(headersCopy);
      return;
    }

    const bodyFieldsCopy = { ...bodyFields };
    delete bodyFieldsCopy[id];
    setBodyFields(bodyFieldsCopy);
  };

  return (
    <>
      <Dialog fullWidth onClose={onClose} aria-labelledby={MODAL_ID} open={open}>
        <Title id={`${MODAL_ID}-title`} onClose={onClose}>
          Choose flow
        </Title>
        <Content className={classes.page}>
          {step === 0 ? (
            <Select
              value={flowSelection}
              onChange={(value) => setFlowSelection(value as string)}
              items={newFlowsToExecute}
            />
          ) : (
            <>
              {loading && (
                <div
                  style={{
                    position: 'absolute',
                    inset: 0,
                    background: 'rgba(255, 255, 255, 0.2)',
                    backdropFilter: 'blur(2px)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1,
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              )}
              <div>
                <BodyFields
                  title="Headers"
                  fields={headers}
                  onChange={handleFieldChange('headers')}
                  onDelete={handleFieldDelete('headers')}
                />
              </div>
              <div style={{ marginTop: 15 }}>
                <BodyFields
                  title="Body"
                  fields={bodyFields}
                  onChange={handleFieldChange('bodyFields')}
                  onDelete={handleFieldDelete('bodyFields')}
                />
              </div>
            </>
          )}
        </Content>
        <Actions>
          <Button onClick={handleBack} color="default">
            {step === 0 ? 'Cancel' : 'Back'}
          </Button>
          <Button disabled={!flowSelection} onClick={handleNext} color="primary">
            {step === 0 ? 'Next' : 'Save'}
          </Button>
        </Actions>
      </Dialog>
    </>
  );
};

export default FlowExecuteCustomizeModalUI;
