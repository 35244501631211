import { Area } from '../interfaces/area';
import Areas from '../mocks/areas.json';

export const getAreasMiddleware = async (
  categoryId: string,
): Promise<{
  isSuccess: boolean;
  data: Area[];
}> => {
  try {
    const { areas } = Areas;
    const areasByCategoryId = areas.filter((el) => el.category_id === categoryId);

    return { isSuccess: true, data: areasByCategoryId };
  } catch (error) {
    return { isSuccess: false, data: [] };
  }
};
