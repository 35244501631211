import DataConnectionsUI from 'modules/data-spaces/data-connections';
import ApplicationEndpointsUI from 'modules/data-spaces/application-endpoints';
import DataSpacesLogUI from 'modules/data-spaces/data-spaces-log';

export const DataSpacesPages = [
  {
    id: 'data-connections',
    label: 'Data Connections',
    disabled: false,
    item: DataConnectionsUI,
  },
  {
    id: 'application-endpoints',
    label: 'Application Endpoints',
    disabled: false,
    item: ApplicationEndpointsUI,
  },
  {
    id: 'data-spaces-log',
    label: 'Data Spaces Log',
    disabled: false,
    item: DataSpacesLogUI,
  },
];
