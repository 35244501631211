import axios from 'axios';
import { checkCurrentSession } from 'configure/azure-ad-b2c';

import { authThunks } from 'modules/auth';
import { Auth, instance } from 'modules/rest';
import Storage from 'modules/storage';
import { REDIRECT_ERROR_CODE } from 'project-constants';

import { TIME_OUT_EXPIRES_CODE, INVALID_REFRESH_TOKEN_CODE } from '../constants';

export default (dispatch) => {
  let refreshRequest: Promise<any> | null;

  return async (error) => {
    if (axios.isCancel(error) || error.code === TIME_OUT_EXPIRES_CODE) {
      throw error;
    }

    const errorStatus = error.response.status;

    const errorMsg = error.response.data?.error || '';
    const [errorCode, meta] = typeof errorMsg === 'string' ? errorMsg.split('|') : ['', ''];
    const accessToken = Storage.getAccessToken();
    const idToken = Storage.getIdToken();
    const refreshToken = Storage.getRefreshToken();

    if (errorCode === REDIRECT_ERROR_CODE) {
      setTimeout(() => {
        window.location.assign(meta);
      }, 3000);

      throw error;
    }

    if (!accessToken && !refreshRequest) {
      throw error;
    }

    if (
      (!refreshToken && !idToken) ||
      errorCode === INVALID_REFRESH_TOKEN_CODE ||
      (errorStatus === 401 && error.config.isRepeatedRequest)
    ) {
      await dispatch(authThunks.logout());
      throw error;
    }

    if (errorStatus !== 401 || error.config.isRepeatedRequest) {
      throw error; // Already tried to refresh tokens and error exists
    }

    if (idToken) {
      await checkCurrentSession(dispatch);
    } else {
      if (!refreshRequest) {
        refreshRequest = Auth.refreshTokens(refreshToken);
      }

      try {
        const { data } = await refreshRequest;

        if (data.accessToken !== Storage.getAccessToken()) {
          Storage.setAccessToken(data.accessToken);
          Storage.setRefreshToken(data.refreshToken);
        }
      } finally {
        refreshRequest = null;
      }
    }

    const originalReqConfig = error.config.originalReqConfig || {};

    return instance({ ...originalReqConfig, isRepeatedRequest: true });
  };
};
