import * as React from 'react';
import * as BorNeoColors from 'modules/shared/styles/colors';
import Button from '../Button';

interface Props {
  currentTab: string;
  onClick: (tab: string) => void;
  breadcrumbs: { tab: string; name: string }[];
}
const Breadcrumb: React.FC<Props> = ({ currentTab, onClick, breadcrumbs }) => {
  return (
    <>
      {breadcrumbs.map((el, index) => {
        const isCurrentTab = currentTab === el.tab;
        return (
          <Button
            key={el.tab}
            variant={isCurrentTab ? 'contained' : 'outlined'}
            style={{
              marginLeft: index === 0 ? 0 : 10,
              color: isCurrentTab ? '#fff' : '#000',
              backgroundColor: isCurrentTab ? BorNeoColors.primaryColor1 : 'rgb(195 225 255)',
              textTransform: 'unset',
            }}
            onClick={() => onClick(el.tab)}
          >
            {el.name}
          </Button>
        );
      })}
    </>
  );
};

export default Breadcrumb;
