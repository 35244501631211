import { makeStyles } from '@material-ui/core';
import * as BorNeoParts from 'modules/shared/styles/global-classes';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  container: {
    ...BorNeoParts.container,
    height: '100%',
    display: 'flex',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    outline: 'none',
  },
  card: {
    opacity: 1,
    border: 0,
    margin: '30px 0',
    padding: '40px 0',
    borderRadius: 6,
    color: 'rgba(0, 0, 0, 0.87)',
    background: BorNeoColors.primaryColor4,
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    overflow: 'auto',
    transition: 'all 150ms linear',
    maxHeight: '70vh',
    overflowX: 'hidden',
  },
  cardAnimation: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
    transition: 'all 150ms linear',
  },
  cardTitle: {
    minHeight: 'auto',
    fontWeight: 300,
    marginBottom: '20px',
    textDecoration: 'none',
    fontSize: '36px',
    textAlign: 'center',
  },
  inputIcon: {
    color: '#555',
  },
  registerButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  registerButton: {
    borderRadius: 20,
    backgroundColor: BorNeoColors.primaryColor2,
    '&:hover': {
      backgroundColor: BorNeoColors.primaryColor1,
    },
    color: BorNeoColors.accentColor1,
  },
  checkbox: {
    padding: '0 !important',
    margin: 9,
  },
  checkboxInfo: {
    fontSize: '0.75rem',
    color: '#aaaaaa',
    fontWeight: 400,
  },
  checkboxLink: {
    color: '#9b27b0',
  },
  errorInfo: {
    marginTop: 20,
  },
  list: {
    marginLeft: 10,
    listStyleType: 'circle',
  },
}));
