import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { User } from '../types';

const defaultState = {
  id: '',
  email: '',
  username: '',
  role: '',
  connections: [],
  adminConnections: [],
};

const reducerMap: ReducerMap<User, any> = {
  [`${combineActions(actions.authLoginSuccess, actions.authLoadProfileSuccess, actions.authRegisterSuccess)}`]: (
    state,
    { payload },
  ) => {
    return {
      ...state,
      ...payload,
    };
  },
  [`${actions.authLogout}`]: () => ({
    ...defaultState,
  }),
};

export default handleActions(reducerMap, defaultState);
