import { memo, useContext, useMemo } from 'react';
import loadable from '@loadable/component';
import ProjectsUi from 'modules/capital-projects/projects-ui';
import { authTools } from 'modules/auth';
import { CREATE_PROJECT_MODAL_HASH, IMPORT_PROJECT_MODAL_HASH } from 'modules/capital-projects/constants';

import { AuthRoutesPath } from 'main/routes';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useStyles } from './styles';

const SetHashButton = loadable(
  () => import(/* webpackChunkName: "SetHashButton" */ 'modules/shared/components/Buttons/SetHashButton'),
);
const NavigateButton = loadable(
  () => import(/* webpackChunkName: "NavigateButton" */ 'modules/shared/components/Buttons/NavigateButton'),
);
const CreateProjectModalUi = loadable(
  () => import(/* webpackChunkName: "CreateProjectModalUi" */ 'modules/capital-projects/create-project-modal-ui'),
);

const UpdateProjectModalUi = loadable(
  () => import(/* webpackChunkName: "UpdateProjectModalUi" */ 'modules/capital-projects/update-project-modal-ui'),
);

const ImportProjectModalUi = loadable(
  () => import(/* webpackChunkName: "ImportProjectModalUi" */ 'modules/capital-projects/import-project-modal-ui'),
);

const AdminOnlyHashButton = authTools.adminOnly(SetHashButton);
const AdminOnlyPathButton = authTools.globalAdminOnly(NavigateButton);
const AdminOnlyCreateProjectModalUi = authTools.adminOnly(CreateProjectModalUi);
const AdminOnlyUpdateProjectModalUi = authTools.adminOnly(UpdateProjectModalUi);
const AdminOnlyImportProjectModalUi = authTools.adminOnly(ImportProjectModalUi);

const CapitalProjects = () => {
  const classes = useStyles();

  const { connection, projectId } = useProjectInfoFromSearch();

  const { loading, projectsPerEnv } = useContext(ProjectsContext);

  const projects = useMemo(() => {
    if (!projectId) {
      return projectsPerEnv[connection];
    }

    return projectsPerEnv[connection].filter((p) => p.projectId === projectId);
  }, [connection, projectId, projectsPerEnv]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.select} />
        <div className={classes.buttons}>
          <AdminOnlyPathButton title="Connection Manager" path={AuthRoutesPath.ConnectionManager} />
          <AdminOnlyHashButton title="Import" hash={IMPORT_PROJECT_MODAL_HASH} />
          <AdminOnlyHashButton title="Create" hash={CREATE_PROJECT_MODAL_HASH} />
        </div>
      </div>
      <div className={classes.page}>
        <ProjectsUi projects={projects} loading={loading} />
      </div>
      <AdminOnlyCreateProjectModalUi />
      <AdminOnlyUpdateProjectModalUi />
      <AdminOnlyImportProjectModalUi />
    </>
  );
};

export default memo(CapitalProjects);
