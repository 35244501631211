import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { LOAD_DELTA_CATEGORIES } from '../gql';

export const useLoadDeltaCategories = ({ connection, projectId }, skip = false) => {
  const [categories, setCategories] = useState<string[]>([]);

  const { loading, data } = useQuery(LOAD_DELTA_CATEGORIES, {
    variables: {
      connection,
      projectId,
    },
    skip: !connection || !projectId || skip,
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    setCategories(data?.readDeltaCategories?.folders || []);
  }, [data]);

  return {
    loading,
    categories,
    updateCategories: setCategories,
  };
};
