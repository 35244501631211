import * as React from 'react';
import { getNotesMiddleware } from '../middlewares/note';
import { Note } from '../interfaces/note';

export const useNotes = (categoryId: string) => {
  const [note, setNote] = React.useState<Note | null>(null);

  React.useEffect(() => {
    (async () => {
      const { isSuccess, data } = await getNotesMiddleware(categoryId);
      if (isSuccess) {
        setNote(data);
      }
    })();
  }, [categoryId]);
  return { note, setNote };
};
