import { useQuery } from '@apollo/client';
import { LOAD_DASHBOARD_DATA_PER_TYPE } from 'modules/new-dashboard/gql/queries';

type Children = {
  loading: boolean;
  data: {
    name: string;
    children: any[];
  };
};
const useDataStatsHook = (connection: string, projectId: string, id: string) => {
  const { loading, data } = useQuery(LOAD_DASHBOARD_DATA_PER_TYPE, {
    variables: {
      connection,
      projectId,
      type: id,
    },
    skip: !connection || !projectId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first',
  });
  const dataReturn: Children = {
    loading,
    data: {
      name: id,
      children: [],
    },
  };
  const dataResponse = data?.dashboardDataPerType;
  if (!dataResponse) {
    return dataReturn;
  }
  const items = dataResponse.items ?? [];
  dataReturn.data.children = items;
  return dataReturn;
};

export default useDataStatsHook;
