import { memo } from 'react';
import { Add } from '@material-ui/icons';
import { Button } from '@material-ui/core';

const GoToDataSpaceApp = () => {
  const path = process.env.REACT_APP_DATA_SPACE_FE_URL;
  if (!path) {
    // eslint-disable-next-line no-console
    console.error('Please, provide "REACT_APP_DATA_SPACE_FE_URL" to your .env file');
    return null;
  }
  return (
    <Button target="_blank" variant="contained" color="primary" href={path as string}>
      <Add />
    </Button>
  );
};

export default memo(GoToDataSpaceApp);
