import * as React from 'react';
import { getAreasMiddleware } from '../middlewares/area';
import { Area } from '../interfaces/area';

export const useAreas = (categoryId: string) => {
  const [areas, setAreas] = React.useState<Area[]>([]);

  React.useEffect(() => {
    if (!categoryId) {
      return;
    }

    (async () => {
      const { isSuccess, data } = await getAreasMiddleware(categoryId);
      if (isSuccess) {
        setAreas([...data]);
      }
    })();
  }, [categoryId]);

  return { areas, setAreas };
};
