import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  mainContainer: {
    flex: 1,
    fontSize: '13px',
    background: BorNeoColors.primaryColor6,
    position: 'relative',
  },
  wrapper: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
}));
