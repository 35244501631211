import { Grid, Typography } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import * as React from 'react';
import Button from '../components/Button';
import { useLegends } from '../hooks/useLegends';
import { useStatuses } from '../hooks/useStatuses';
import { Category } from '../interfaces/category';
import AddStatusDialog from '../add-status-dialog-ui';
import DataTable from '../data-table-ui';
import Notes from '../notes-ui';
import { useStyles } from './styles';

interface Props {
  category: Category;
  legendIds: string[];
}
const Guide: React.FC<Props> = ({ category, legendIds }) => {
  const [forceUpdate, setForceUpdate] = React.useState(Date.now());
  const { statuses, setStatuses } = useStatuses(category?.id, legendIds, forceUpdate);
  const [selectionRow, setSelectionRow] = React.useState<GridSelectionModel>([]);
  const { legends } = useLegends(category?.id);
  const [openDialog, setOpenDialog] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.root}>
        <Grid container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Digital Implementation Guide
            </Typography>
            <Button
              variant="contained"
              onClick={() => setOpenDialog(true)}
              style={{ marginBottom: statuses?.length ? 0 : 10 }}
            >
              Add Row
            </Button>
          </Grid>
          <DataTable
            data={statuses}
            setData={setStatuses}
            legends={legends}
            selectionRow={selectionRow}
            setSelectionRow={setSelectionRow}
          />
        </Grid>
      </Grid>
      <Notes categoryId={category.id} />

      <AddStatusDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        callback={() => setForceUpdate(Date.now())}
        categoryId={category.id}
        legends={legends}
      />
    </>
  );
};

export default React.memo(Guide);
