import { useQuery } from '@apollo/client';
import { LOAD_MAPPINGS_HISTORY } from '../gql';
import { HistoryItem } from '../types';

export const useLoadHistoryItems = ({ connection, projectId, dataTemplate }) => {
  const { loading, data } = useQuery(LOAD_MAPPINGS_HISTORY, {
    variables: {
      connection,
      projectId,
      dataTemplate,
    },
    skip: !connection || !projectId || !dataTemplate,
  });

  const historyItems: HistoryItem[] = data?.mappingsHistory || [];

  return {
    loading,
    historyItems,
  };
};
