/* eslint-disable import/no-extraneous-dependencies */
import { setContext } from '@apollo/client/link/context';
import Storage from 'modules/storage';

export const createAuthLink = () => {
  const authLink = setContext((_, { headers }) => {
    const accessToken = Storage.getAccessToken();
    const idToken = Storage.getIdToken();

    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        'x-id-token': idToken,
      },
    };
  });

  return authLink;
};
