import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import Select from 'modules/shared/components/Select2';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useStyles } from './styles';
import { WizzardContext } from '../wizzard-context/context';
import { HistoryContext } from '../history-context/context';
import { usePublishSteps } from '../hooks/usePublishSteps';

const MODAL_ID = 'copy-modal';

const Modal = ({ isOpen, close }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedProject, setSelectedProject] = useState('');
  const { connection, projectId } = useProjectInfoFromSearch();

  const { publish, publishing } = usePublishSteps({ connection, projectId: selectedProject });

  const { projectsPerEnv } = useContext(ProjectsContext);
  const { templateId } = useContext(WizzardContext);

  const { latestHistoryItem } = useContext(HistoryContext);

  useEffect(() => {
    if (isOpen) {
      setSelectedProject('');
    }
  }, [isOpen]);

  const selectItems = useMemo(
    () =>
      projectsPerEnv[connection]
        .filter((it) => it.projectId !== projectId)
        .map((it) => ({
          value: it.projectId,
          label: it.projectName,
        })),
    [connection, projectId, projectsPerEnv],
  );

  const handleSubmit = useCallback(async () => {
    if (!projectId) {
      return enqueueSnackbar('Please specify project', BASE_ERROR_NOTIFICATION_OPTIONS);
    }

    await publish(latestHistoryItem.steps, latestHistoryItem.selectedStep, templateId);

    close();
  }, [projectId, publish, latestHistoryItem.steps, latestHistoryItem.selectedStep, templateId, close, enqueueSnackbar]);

  const handleClose = useCallback(() => {
    if (publishing) {
      return enqueueSnackbar('Please wait until copy completes', BASE_ERROR_NOTIFICATION_OPTIONS);
    }

    close();
  }, [enqueueSnackbar, publishing, close]);

  return (
    <Dialog
      classes={{
        paper: classes.dialog,
      }}
      fullWidth
      onClose={handleClose}
      aria-labelledby={MODAL_ID}
      open={isOpen}
    >
      <Title id={MODAL_ID} onClose={handleClose}>
        Copy
      </Title>
      <Content className={classes.dialogContent}>
        <Select
          id="config-project"
          value={[selectedProject]}
          options={selectItems}
          disabled={publishing}
          className={classes.select}
          disableClear
          onChange={(val) => setSelectedProject(val[0])}
        />
      </Content>
      <Actions>
        <Button disabled={publishing} variant="contained" color="primary" onClick={handleSubmit}>
          Copy
        </Button>
      </Actions>
    </Dialog>
  );
};

export default memo(Modal);
