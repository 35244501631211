import { memo } from 'react';
import classnames from 'classnames';
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';

import GoToDataSpaceApp from '../go-to-data-space-app';

import { useStyles } from './styles';

const DataConnectionsUI = () => {
  const classes = useStyles();

  const tableData = [
    {
      data_connection: 'https://snapshooter.com/assets/img/marketing/providers/aws-s3.svg',
      connection_type: 'AWS S3',
      sub_type: 'In Production',
      responsible_role: 'AWP Coordinator',
      data_io: {
        registered: '',
        validated: '',
        production: '',
      },
    },
  ];

  return (
    <>
      <div className={classes.buttons}>
        <GoToDataSpaceApp />
      </div>
      <TableContainer className={classes.container} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classnames(classes.groups, classes.actionsCellWrap)} align="center">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell align="center">Data Connection</TableCell>
              <TableCell align="center">Connection Type</TableCell>
              <TableCell align="center">Sub Type</TableCell>
              <TableCell align="center">Responsible Role</TableCell>
              <TableCell align="center" className={classnames(classes.actionsCellWrap)}>
                <div className={classes.actionsCell}>
                  <div>Registered</div>
                  <div>Validated</div>
                  <div>Production</div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => {
              return (
                <TableRow key={row.data_connection}>
                  <TableCell align="center">
                    <img className={classes.tableImg} alt={row.connection_type} src={row.data_connection} />
                  </TableCell>
                  <TableCell align="center">{row.connection_type}</TableCell>
                  <TableCell align="center">{row.responsible_role}</TableCell>
                  <TableCell align="center">{row.sub_type}</TableCell>
                  <TableCell className={classnames(classes.actionsCellWrap)} align="center">
                    <div className={classes.actionsCell}>
                      <div>{row.data_io.registered}</div>
                      <div>{row.data_io.validated}</div>
                      <div>{row.data_io.production}</div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(DataConnectionsUI);
