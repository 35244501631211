import { Discipline } from '../interfaces/discipline';
import Disciplines from '../mocks/disciplines.json';

export const getDisciplinesMiddleware = async (
  categoryId: string,
): Promise<{
  isSuccess: boolean;
  data: Discipline[];
}> => {
  try {
    const { disciplines } = Disciplines;
    return {
      isSuccess: true,
      data: disciplines.filter((el) => el.category_id === categoryId) as any,
    };
  } catch (error) {
    return { isSuccess: false, data: [] };
  }
};
