import { memo, useCallback, useContext } from 'react';
import { CloudUpload, History, ContentCopy } from '@mui/icons-material';
import { IconButton, CircularProgress, Chip } from '@mui/material';
import {
  HistoryContext,
  HistoryHandlersContext,
} from 'modules/digital-threads-mapping-wizzard/history-context/context';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import HistoryModalUi from 'modules/digital-threads-mapping-wizzard/history-modal-ui';
import classNames from 'classnames';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import { useToggle } from 'modules/shared/hooks/base';
import { useStyles } from './styles';
import Provider from '../wizzard-context';
import HistoryProvider from '../history-context';
import Stepper from '../stepper-ui';
import Builder from './Builder';
import { usePublishSteps } from '../hooks/usePublishSteps';
import { WizzardContext, WizzardHandlersContext } from '../wizzard-context/context';
import CopyModalUi from '../copy-modal-ui';

const WizzardUi = () => {
  const classes = useStyles();
  const { connection, projectId } = useProjectInfoFromSearch();
  const { publish, publishing } = usePublishSteps({ connection, projectId });
  const { itemsLoading, historyItems, historyModalOpen } = useContext(HistoryContext);
  const { openHistoryModal } = useContext(HistoryHandlersContext);
  const { baseInfoLoading, templateId, isDraft, steps, selectedStep } = useContext(WizzardContext);
  const { resetState } = useContext(WizzardHandlersContext);

  const editable = useAdminOrSuperUser(connection, projectId);

  const [isCopyModalOpen, { activate: openCopyModal, deactivate: closeCopyModal }] = useToggle();

  const handlePublish = useCallback(() => {
    publish(steps, selectedStep, templateId);
  }, [publish, steps, selectedStep, templateId]);

  if (baseInfoLoading || itemsLoading || !templateId) {
    return (
      <div className={classNames(classes.container, classes.center)}>
        <CircularProgress size={50} />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.stepper}>{editable && <Stepper />}</div>
      <div className={classes.content}>
        <div className={classes.actionsWrapper}>
          <div className={classes.buttons}>
            {isDraft && (
              <div className={classes.rootChipDraft} role="presentation" onClick={resetState}>
                <Chip className={`${classes.chipDraft} draft`} label="Rough Draft" />
                <Chip className={`${classes.chipDraft} remove`} label="Reset" />
              </div>
            )}
            <IconButton
              onClick={openCopyModal}
              title="Copy"
              disabled={publishing || itemsLoading || !historyItems.length}
            >
              {itemsLoading ? (
                <div className={classes.spinnerWrap}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <ContentCopy />
              )}
            </IconButton>
            <IconButton
              onClick={openHistoryModal}
              title="History"
              disabled={publishing || itemsLoading || !historyItems.length}
            >
              {itemsLoading ? (
                <div className={classes.spinnerWrap}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <History />
              )}
            </IconButton>
            {editable && (
              <IconButton onClick={handlePublish} title="Publish" disabled={!isDraft}>
                {publishing ? (
                  <div className={classes.spinnerWrap}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <CloudUpload />
                )}
              </IconButton>
            )}
          </div>
        </div>
        {!historyModalOpen && <Builder />}
        <CopyModalUi isOpen={isCopyModalOpen} close={closeCopyModal} />
      </div>
    </div>
  );
};

const WithProvider = (props) => {
  return (
    <HistoryProvider {...props}>
      <Provider {...props}>
        <WizzardUi {...props} />
        <HistoryModalUi />
      </Provider>
    </HistoryProvider>
  );
};

export default memo(WithProvider);
