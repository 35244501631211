import { memo, useCallback, useMemo, useState } from 'react';
import { Button, Box } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { useSearch } from 'modules/shared/hooks/url';

import StatusDiamond from 'modules/shared/components/StatusDiamond';
import EditingWrapper from 'modules/shared/components/Grid/wrappers/EditingWrapper';
import ToolbarWrapper from 'modules/shared/components/Grid/wrappers/ToolbarWrapper';
import Grid from 'modules/shared/components/Grid';

import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import IndexViewModalUI from '../index-view-modal-ui';

import { useLoadDataTemplatesWithVersion } from '../hooks';

import { useStyles } from './styles';

const DataGrid = ToolbarWrapper(EditingWrapper(Grid));

const IndexView = () => {
  const classes = useStyles();
  const { get, set } = useSearch();

  const { projectId, connection } = useProjectInfoFromSearch();
  const dataTemplate = get('dtIndexId');
  const [versionStatusId, setVersionStatusId] = useState('');
  const [dtVersionId, setDtVersionId] = useState('');
  const type = get('type');

  const statusColor = [
    {
      value: 'No Issues',
      color: 'green',
    },
    {
      value: 'Minor Issues',
      color: 'yellow',
    },
    {
      value: 'Major Issues',
      color: 'orange',
    },
    {
      value: 'Escalated',
      color: 'red',
    },
    {
      value: '',
      color: 'grey',
    },
  ];

  const { load } = useLoadDataTemplatesWithVersion(connection, projectId);

  const customStore = useMemo(
    () =>
      new CustomStore({
        load: (options) => {
          return load({
            order: options.sort && options.sort[0].selector,
            desc: options.sort && options.sort[0].desc,
            limit: options.take,
            offset: options.skip,
          });
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId],
  );

  const dataSource = useMemo(() => new DataSource({ store: customStore }), [customStore]);

  const handleCloseModal = useCallback(() => {
    set({
      dtIndexId: '',
      type: '',
    });
    dataSource.reload();
  }, [set, dataSource]);

  const handleDiaryClmClick = useCallback(
    (id) => {
      set({
        dtIndexId: id,
        type: 'diary',
      });
    },
    [set],
  );

  const handleDtStatusClmClick = useCallback(
    (id, vId, dtvId) => {
      set({
        dtIndexId: id,
        type: 'status',
      });
      setVersionStatusId(vId);
      setDtVersionId(dtvId);
    },
    [set, setVersionStatusId, setDtVersionId],
  );

  return (
    <Box className={classes.container}>
      <Box className={classes.wrapper}>
        <DataGrid
          dataSource={dataSource}
          gridName="digital-threads-index"
          gridOptions={{
            height: '100%',
          }}
          columns={[
            {
              caption: 'Status',
              width: 'auto',
              allowSorting: false,
              cellRender: ({ data }) => {
                const vColor: any = statusColor.find((v) => v.value === data.versionStatus)?.color || 'grey';
                if (data.version) {
                  return (
                    <div
                      role="button"
                      onClick={() =>
                        handleDtStatusClmClick(
                          data.template.templateId,
                          data.version !== 0 ? data.versionStatusId : 'new',
                          data.versionId,
                        )
                      }
                      onKeyDown={() =>
                        handleDtStatusClmClick(
                          data.template.templateId,
                          data.version !== 0 ? data.versionStatusId : 'new',
                          data.versionId,
                        )
                      }
                      tabIndex={0}
                    >
                      <StatusDiamond
                        color={vColor}
                        style={{
                          verticalAlign: 'middle',
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <div role="button">
                    <StatusDiamond
                      color="grey"
                      style={{
                        verticalAlign: 'middle',
                      }}
                    />
                  </div>
                );
              },
            },
            {
              dataField: 'template.templateId',
              caption: 'ID',
              cellRender: ({ data }) => (
                <Button
                  className={classes.notesButton}
                  color="primary"
                  onClick={() => {
                    handleDiaryClmClick(data.template.templateId);
                  }}
                >
                  {data.template.templateId}
                </Button>
              ),
            },
            { dataField: 'version', caption: 'Version', allowSorting: false },
            {
              dataField: 'template.deliverableProjectData',
              caption: 'Name',
            },
            { dataField: 'template.timing', caption: 'Description', calculateSortValue: 'timing' },
            {
              dataField: 'template.digitalthreadInfo.title',
              caption: 'Category',
              calculateSortValue: 'digitalthreadInfo.title',
            },
          ]}
        />

        <IndexViewModalUI
          key={dataTemplate}
          open={Boolean(dataTemplate)}
          onClose={handleCloseModal}
          dataTemplate={dataTemplate}
          connection={connection}
          projectId={projectId}
          type={type}
          versionStatusId={versionStatusId}
          dtVersionId={dtVersionId}
        />
      </Box>
    </Box>
  );
};

export default memo(IndexView);
