import { gql } from '@apollo/client';

export const LOAD_FLOW_CUSTOMIZE = gql`
  query LoadFlowCustomize($flowId: String!, $boardId: String!, $connection: String!) {
    getFlowCustomizeByFlowIdAndBoardId(flow_id: $flowId, board_id: $boardId, connection: $connection) {
      id
      fkFlowId
      bodyFields
      headers
    }
  }
`;
