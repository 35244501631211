import { memo, useCallback, useContext, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { Dialog, Title, Content, Actions } from 'modules/shared/components/Dialog';
import Input from 'modules/shared/components/Input';
import Select from 'modules/shared/components/Select2';
import DatePicker from 'modules/shared/components/DatePicker';
import { InputLabel, FormHelperText, IconButton } from '@mui/material';
import { StepExecuteContext } from 'modules/project-flow-solution/board-ui/contexts/StepExecuteContext';
import RadioGroup from 'modules/shared/components/RadioGroup';
import { noop } from 'modules/shared/utils';
import { Refresh } from '@mui/icons-material';
import { useStyles } from './styles';
import FlowExecuteCustomizeModalUI from '../FlowExecuteCustomizeModalUI';
import DraggableFlows from '../DraggableFlows';

const MODAL_ID = 'edit-poc-card-modal-title';

const EditCardModal = ({
  open,
  onClose,
  title,
  shapeId,
  dataspaceLink,
  flowLink,
  docLink,
  flowsToExecute,
  datePlan,
  dateActual,
  actionable,
  flowsLoading,
  flows,
  cardSaving,
  onUpdateCard,
  refetch,
  isRunSequentially = false,
  flowsError,
}) => {
  const classes = useStyles();

  const { boardId } = useContext(StepExecuteContext);

  const formId = `${title}-id`;

  const [values, setValues] = useState({
    title,
    dataspaceLink,
    flowLink,
    docLink,
    datePlan,
    dateActual,
    isRunSequentially,
  });

  const [openModal, setOpenModal] = useState(false);

  const handleChange = useCallback(
    ({ target: { id, value } }) => {
      setValues({
        ...values,
        [id]: value,
      });
    },
    [values],
  );

  const handlePickerChange = useCallback(
    (value, id) => {
      setValues({
        ...values,
        [id]: value,
      });
    },
    [values],
  );

  const [selectedFlows, setSelectedFlows] = useState<string[]>(flowsToExecute);

  const handleChangeSelectedFlows = useCallback((newFlows) => {
    setSelectedFlows(newFlows as string[]);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const { title: newTitle, ...rest } = values;

    const metaToChange = actionable
      ? { ...rest, flowsToExecute: selectedFlows }
      : {
          datePlan: values.datePlan,
          dateActual: values.dateActual,
        };

    onUpdateCard(onClose, {
      shapeId,
      title: newTitle,
      metadata: {
        actionable,
        ...metaToChange,
      },
    });
  };

  const close = cardSaving ? noop : onClose;
  const contentStyles = cardSaving ? { overflow: 'hidden' } : {};

  const flowsOrder = selectedFlows.map((s) => {
    return {
      id: s,
      name: (flows.find((f) => f.flowId === s)?.flowName ?? '') as string,
    };
  });

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open, refetch]);

  return (
    <>
      <Dialog
        classes={{
          paper: classes.dialog,
        }}
        fullWidth
        onClose={close}
        aria-labelledby={MODAL_ID}
        open={open}
      >
        <Title id={MODAL_ID} onClose={close}>
          {title}
        </Title>
        <Content dividers style={contentStyles}>
          <form className={classes.form} id={formId} onSubmit={handleSubmit}>
            {cardSaving && (
              <div className={classes.globalLoading}>
                <CircularProgress />
              </div>
            )}
            <Input
              labelText="Title"
              id="title"
              labelProps={{
                shrink: true,
              }}
              formControlProps={{
                disabled: cardSaving,
                fullWidth: true,
                className: classes.formControl,
                required: true,
              }}
              inputProps={{
                value: values.title,
                onChange: handleChange,
              }}
            />
            <DatePicker
              formControlProps={{
                disabled: cardSaving,
                fullWidth: true,
                className: classes.formControl,
              }}
              id="datePlan"
              label="Date Plan"
              date={values.datePlan}
              changeDate={handlePickerChange}
              midDaySelection
              disabled={cardSaving}
              readOnly={Boolean(dateActual)}
            />
            <DatePicker
              formControlProps={{
                disabled: cardSaving,
                fullWidth: true,
                className: classes.formControl,
              }}
              id="dateActual"
              label="Date Actual"
              date={values.dateActual}
              changeDate={handlePickerChange}
              midDaySelection
              disabled={cardSaving}
              readOnly={Boolean(dateActual)}
            />
            {actionable && (
              <>
                <Input
                  labelText="Dataspace Link"
                  id="dataspaceLink"
                  labelProps={{
                    shrink: true,
                  }}
                  formControlProps={{
                    disabled: cardSaving,
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputProps={{
                    value: values.dataspaceLink,
                    onChange: handleChange,
                  }}
                />
                <Input
                  labelText="Flow Link"
                  id="flowLink"
                  labelProps={{
                    shrink: true,
                  }}
                  formControlProps={{
                    disabled: cardSaving,
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputProps={{
                    value: values.flowLink,
                    onChange: handleChange,
                  }}
                />
                <Input
                  labelText="Documentation Link"
                  id="docLink"
                  labelProps={{
                    shrink: true,
                  }}
                  formControlProps={{
                    disabled: cardSaving,
                    fullWidth: true,
                    className: classes.formControl,
                  }}
                  inputProps={{
                    value: values.docLink,
                    onChange: handleChange,
                  }}
                />
                <div className={classes.formControl} style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <InputLabel shrink>Flows To Execute</InputLabel>
                    {selectedFlows.length && boardId ? (
                      <InputLabel
                        shrink
                        style={{ color: '#0668b7', cursor: 'pointer' }}
                        onClick={() => setOpenModal(true)}
                      >
                        Set customize parameters
                      </InputLabel>
                    ) : null}
                  </div>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <Select
                      id="flows-selector"
                      value={selectedFlows}
                      onChange={handleChangeSelectedFlows}
                      options={flows.map((f) => ({
                        label: `${f.flowName} (${f.isActive ? 'Active' : 'Inactive'})`,
                        value: f.flowId,
                      }))}
                      loading={flowsLoading}
                      disabled={flowsLoading || cardSaving}
                      isMulti
                    />
                    <IconButton
                      onClick={() => {
                        refetch();
                      }}
                    >
                      <Refresh className={classes.refreshButtonIcon} />
                    </IconButton>
                  </div>
                  {flowsError && (
                    <FormHelperText>
                      <span className={classes.error}>Error while loading occured</span>
                    </FormHelperText>
                  )}
                </div>
                <RadioGroup
                  value={values.isRunSequentially}
                  label="How are the flows execute?"
                  items={[
                    { label: 'Parallel', value: false },
                    { label: 'Sequentially', value: true },
                  ]}
                  onChange={(value) =>
                    setValues({
                      ...values,
                      isRunSequentially: value === 'true',
                    })
                  }
                />
                {Boolean(selectedFlows.length > 1 && values.isRunSequentially) && (
                  <DraggableFlows items={flowsOrder} onChange={(items) => setSelectedFlows(items.map((i) => i.id))} />
                )}
              </>
            )}
          </form>
        </Content>
        <Actions>
          <Button disabled={cardSaving} onClick={close} color="default">
            Cancel
          </Button>
          <Button disabled={cardSaving} type="submit" form={formId} color="primary">
            Save
          </Button>
        </Actions>
      </Dialog>
      <FlowExecuteCustomizeModalUI
        open={openModal}
        onClose={() => setOpenModal(false)}
        flowsToExecute={flows}
        selectedFlows={selectedFlows}
      />
    </>
  );
};

export default memo(EditCardModal);
