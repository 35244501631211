import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { CREATE_FLOW_CUSTOMIZE, UPDATE_FLOW_CUSTOMIZE } from '../gql/mutations';
import { LOAD_FLOW_CUSTOMIZE } from '../gql/queries';
import { FlowExecuteCustomize } from '../types';

export const useFlowExecuteCustomizeHook = (flowId: string, boardId: string, connection: string) => {
  const { loading, data, error, refetch } = useQuery(LOAD_FLOW_CUSTOMIZE, {
    variables: {
      connection,
      flowId,
      boardId,
    },
    notifyOnNetworkStatusChange: true,
    context: {
      requestId: `load-flow-customization-${flowId}`,
      queryDeduplication: false,
    },
    skip: !flowId || !boardId,
  });

  const { enqueueSnackbar } = useSnackbar();
  const [create, { loading: createLoading }] = useMutation(CREATE_FLOW_CUSTOMIZE);
  const [update, { loading: updateLoading }] = useMutation(UPDATE_FLOW_CUSTOMIZE);

  const handleCreate = useCallback(
    ({ flowCustomize, connectionName, title }, callback) => {
      return create({
        variables: {
          flowCustomize,
          connection: connectionName,
        },
      })
        .then(() => {
          enqueueSnackbar(`Flow solution "${title}" successfully updated`, BASE_SUCCESS_NOTIFICATION_OPTIONS);
          callback();
        })
        .catch((_error) => {
          enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [create, enqueueSnackbar],
  );

  const handleUpdate = useCallback(
    ({ flowCustomize, flowCustomizeId, connectionName, title }, callback) => {
      return update({
        variables: {
          flowCustomize,
          flowCustomizeId,
          connection: connectionName,
        },
      })
        .then(() => {
          enqueueSnackbar(`Flow solution "${title}" successfully updated`, BASE_SUCCESS_NOTIFICATION_OPTIONS);
          callback();
        })
        .catch((_error) => {
          enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        });
    },
    [update, enqueueSnackbar],
  );
  const flowExecuteCustomize: FlowExecuteCustomize = data?.getFlowCustomizeByFlowIdAndBoardId || null;
  return {
    loading: loading || createLoading || updateLoading,
    create: handleCreate,
    update: handleUpdate,
    flowExecuteCustomize,
    error,
    refetch,
  };
};
