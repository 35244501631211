import { Alert, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import * as React from 'react';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumns,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowsProp,
  GridSelectionModel,
  useGridApiContext,
} from '@mui/x-data-grid';
import { Status } from '../interfaces/status';
import { updateStatusMiddleware } from '../middlewares/status';
import { fireErrorNotiStack, fireSuccessNotiStack } from '../services/notistack';
import Color from '../components/Color';
import Input from '../components/Input';
import { dateFormat } from '../utils/date';
import { Legend } from '../interfaces/legend';
import { useStyles } from './styles';

interface Props {
  data: Status[];
  legends: Legend[];
  setData: (data: Status[]) => void;
  selectionRow: GridSelectionModel;
  setSelectionRow: (newSelectionModel: GridSelectionModel) => void;
}

const DataTable: React.FC<Props> = ({ data, setData, legends, selectionRow, setSelectionRow }) => {
  const classes = useStyles();
  const getDataRows = React.useCallback((): GridRowsProp => {
    return data.map((status, index) => {
      return {
        ...status,
        index,
        color: status.legend?.color?.color,
      };
    });
  }, [data]);

  const onCellEditCommit = React.useCallback(
    async (params: GridCellEditCommitParams) => {
      const isUpdateLegend = params.field === 'legendId';
      const itemExist = data.find((el) => el.id === params.id);
      // @ts-ignore
      if (
        itemExist &&
        // @ts-ignore
        String(itemExist[params.field]) === String(params.value)
      ) {
        return;
      }
      const {
        isSuccess,
        message,
        data: status,
      } = await updateStatusMiddleware(
        params.id as string,
        {
          [params.field]: isUpdateLegend ? params.value : Number(params.value),
        } as any,
      );

      if (isSuccess) {
        fireSuccessNotiStack(message);
        const item = data.find((el) => el.id === params.id);
        // @ts-ignore
        if (item && String(item[params.field]) !== String(params.value)) {
          // @ts-ignore
          item[params.field] = isUpdateLegend ? params.value : Number(params.value);
          if (isUpdateLegend) {
            item.legend = status?.legend;
          }
          setData([...data]);
        }
        return;
      }
      fireErrorNotiStack(message);
    },
    [setData, data],
  );

  const InputEditInline = (props: GridRenderEditCellParams) => {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      apiRef.current.setEditCellValue({ id, field, value: newValue });
    };
    return <Input type="number" variant="standard" value={value} onChange={handleChange} />;
  };

  const SelectEditInline = (props: GridRenderEditCellParams) => {
    const { id, field, value } = props;
    const apiRef = useGridApiContext();

    const handleChange = (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      const legend = legends.find((el) => el.id === newValue);
      apiRef.current.setEditCellValue({
        id,
        field,
        value: legend?.id,
      });
    };
    return (
      <Select value={value} onChange={handleChange}>
        {legends?.map((el) => {
          return (
            <MenuItem key={el.id} value={el.id}>
              {el.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  };

  const renderColor = (params: GridRenderCellParams<any, any, any>) => {
    const legend = legends.find((el) => el.id === params.value);
    return <Color color={legend?.color?.color || ''} />;
  };

  const renderPercent = (params: GridRenderCellParams<any, any, any>) => {
    // eslint-disable-next-line eqeqeq
    return params.value != undefined ? `${params.value}%` : '';
  };

  const renderLastUpdate = (params: GridRenderCellParams<any, any, any>) => {
    // Ignore if same time
    if (params.row.createdAt === params.value) {
      return '';
    }
    return dateFormat(params.value);
  };

  const getDataColumns = (): GridColumns => {
    return [
      {
        field: 'legendId',
        width: 80,
        cellClassName: classes.cellNonePd,
        headerName: '',
        renderCell: renderColor,
        disableColumnMenu: true,
        editable: true,
        renderEditCell: SelectEditInline,
        sortable: false,
      },
      {
        field: 'name',
        headerName: 'Status Checklist - Dev Environment',
        disableColumnMenu: true,
        flex: 1,
        sortable: false,
      },
      {
        field: 'setUp',
        headerName: 'Set-Up',
        width: 140,
        editable: true,
        disableColumnMenu: true,
        renderCell: renderPercent,
        renderEditCell: InputEditInline,
        sortable: false,
      },
      {
        field: 'signOff',
        headerName: 'Sign-Off',
        width: 140,
        editable: true,
        renderEditCell: InputEditInline,
        disableColumnMenu: true,
        renderCell: renderPercent,
        sortable: false,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Update',
        width: 120,
        disableColumnMenu: true,
        renderCell: renderLastUpdate,
        sortable: false,
      },
    ];
  };
  if (!data?.length) {
    return <Alert severity="info">There is no data to show on table.</Alert>;
  }
  return (
    <Grid container className={classes.root}>
      <div
        style={{
          flexGrow: 1,
        }}
      >
        <DataGrid
          rows={getDataRows()}
          columns={getDataColumns()}
          onCellEditCommit={onCellEditCommit}
          getRowClassName={(params) => `Mui-row-${params.row.index % 2 === 0 ? 'odd' : 'even'}`}
          pageSize={6}
          rowsPerPageOptions={[6]}
          selectionModel={selectionRow}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionRow(newSelectionModel);
          }}
        />
      </div>
    </Grid>
  );
};

export default React.memo(DataTable);
