import * as constants from './constants';

const STORAGE: Storage = window.localStorage;

const set = (key: string, value: any) => STORAGE.setItem(key, value);
const get = (key: string) => STORAGE.getItem(key) || '';
const remove = (key: string) => STORAGE.removeItem(key);
const clear = () => STORAGE.clear();

const getAccessToken = () => {
  return get(constants.ACCESS_TOKEN);
};

const getRefreshToken = () => {
  return get(constants.REFRESH_TOKEN);
};

const getIdToken = () => {
  return get(constants.ID_TOKEN);
};

const setAccessToken = (token) => {
  return set(constants.ACCESS_TOKEN, token);
};

const setRefreshToken = (token) => {
  return set(constants.REFRESH_TOKEN, token);
};

const setIdToken = (token) => {
  return set(constants.ID_TOKEN, token);
};

const removeAuthTokens = () => {
  remove(constants.ACCESS_TOKEN);
  remove(constants.REFRESH_TOKEN);
  remove(constants.ID_TOKEN);
};

export default {
  get,
  set,
  remove,
  clear,
  setAccessToken,
  getAccessToken,
  removeAuthTokens,
  getRefreshToken,
  setRefreshToken,
  getIdToken,
  setIdToken,
};
