import { useCallback, useState } from 'react';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';

import { BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { history } from 'configure/browserHistory';

import { resetPassword as resetPasswordApi, sendUsername as sendUsernameApi } from 'modules/rest/api/auth';

export const useForgotCredentials = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [loadingPassword, setLoadingPassword] = useState<boolean>(false);
  const [loadingUsername, setLoadingUsername] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const errorHandler = ({ response }: AxiosError) => {
    if (response) {
      const message = Array.isArray(response.data.message) ? response.data.message.join('. ') : response.data.message;

      return setError(message);
    }

    return setError('Unknown server error response. Please try again later');
  };

  const resetPassword = useCallback(
    (email: string) => {
      setLoadingPassword(true);

      return resetPasswordApi(email)
        .then(() => {
          enqueueSnackbar(
            'Email with reset link has been sent. Please follow link in email body to set a new password',
            BASE_SUCCESS_NOTIFICATION_OPTIONS,
          );
          history.replace('/home');
        })
        .catch(errorHandler)
        .finally(() => setLoadingPassword(false));
    },
    [enqueueSnackbar],
  );

  const sendUsername = useCallback(
    (email: string) => {
      setLoadingUsername(true);

      return sendUsernameApi(email)
        .then(() => {
          enqueueSnackbar(
            'Email with username has been sent. Please check your email inbox',
            BASE_SUCCESS_NOTIFICATION_OPTIONS,
          );
          history.replace('/home');
        })
        .catch(errorHandler)
        .finally(() => setLoadingUsername(false));
    },
    [enqueueSnackbar],
  );

  return {
    resetPassword,
    loadingPassword,
    sendUsername,
    loadingUsername,
    error,
  };
};
