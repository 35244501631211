import * as React from 'react';
import { TableBody as MuiTableBody, TableCell, TableRow } from '@material-ui/core';
import { TableColumnProps, TableRowProps } from '..';

interface Props {
  columns: TableColumnProps[];
  rows: TableRowProps[];
}
const TableBody: React.FC<Props> = ({ columns, rows }) => {
  const renderCell = (column: TableColumnProps, row: TableRowProps, index: number) => {
    if (column.cellRender) {
      return column.cellRender(row, index);
    }
    return row[column.field];
  };
  return (
    <MuiTableBody>
      {rows.map((row, indexRow) => (
        <TableRow
          key={row.id}
          style={{
            backgroundColor: indexRow % 2 === 0 ? 'rgba(0, 0, 0, 0.04)' : 'unset',
          }}
        >
          {columns.map((column) => {
            return (
              <TableCell key={column.field} style={{ borderBottom: 'none' }}>
                {renderCell(column, row, indexRow)}
              </TableCell>
            );
          })}
        </TableRow>
      ))}
    </MuiTableBody>
  );
};

export default React.memo(TableBody);
