import { Grid, Typography, List, ListItem, ListItemButton } from '@mui/material';
import * as React from 'react';
import CircleColor from '../components/CircleColor';
import { Legend } from '../interfaces/legend';

interface Props {
  legends: Legend[];
  legendSelections: string[];
  onClickLegend: (legendId: string) => void;
  style?: React.CSSProperties;
}
const Legends: React.FC<Props> = ({ legends, legendSelections, onClickLegend, style }) => {
  const handleListItemClick = React.useCallback(
    (event, id: string) => {
      if (!id) {
        return;
      }
      onClickLegend(id);
    },
    [onClickLegend],
  );
  return (
    <Grid container style={style}>
      <Grid container>
        <Typography variant="h6">Legend</Typography>
      </Grid>
      <Grid container justifyContent="center">
        <List>
          {legends.map((legend) => {
            const { color, id } = legend;
            const isSelected = legendSelections.includes(id);
            return (
              <ListItem key={legend.id} style={{ padding: 3, pointerEvents: !id ? 'none' : 'initial' }}>
                <ListItemButton
                  selected={isSelected}
                  onClick={(event) => handleListItemClick(event, id)}
                  style={{ pointerEvents: !id ? 'none' : 'initial' }}
                >
                  <Grid container direction="row" alignItems="center">
                    {color && <CircleColor color={color?.color || ''} />}
                    <Typography style={{ marginLeft: color ? 10 : 0 }}>{legend.name}</Typography>
                  </Grid>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  );
};

export default React.memo(Legends);
