export const ITEMS = [
  {
    img: 'https://testing-chiyoda.workpacks.com/static/media/logo.e2cf09c8.png',
    title: 'Marketing',
    description: "We've created the marketing compaign of the website. It was a very interesting collaboration.",
  },
  {
    img: 'https://testing-chiyoda.workpacks.com/static/media/logo.e2cf09c8.png',
    title: 'Fully Coded in HTLM5',
    description: "We've developed the webise with HTML5 and CSS3. The client has access to the code using Github",
  },
  {
    img: 'https://testing-chiyoda.workpacks.com/static/media/logo.e2cf09c8.png',
    title: 'Built Audience',
    description: 'There is also a Fully Customizable CMS Admin Dashboard for this product.',
  },
];
