import * as React from 'react';
import { getAreaDisciplinesMiddleware } from '../middlewares/area-discipline';
import { AreaDiscipline } from '../interfaces/area-discipline';

export const useAreaDisciplines = (categoryId: string) => {
  const [areaDisciplines, setAreaDisciplines] = React.useState<AreaDiscipline[]>([]);

  React.useEffect(() => {
    if (!categoryId) {
      return;
    }

    (async () => {
      const { isSuccess, data } = await getAreaDisciplinesMiddleware(categoryId);
      if (isSuccess) {
        setAreaDisciplines([...data]);
      }
    })();
  }, [categoryId]);

  return { areaDisciplines, setAreaDisciplines };
};
