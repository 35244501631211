import React from 'react';

import { Switch, Route, Redirect } from 'react-router';

import UnauthLayout from 'modules/shared/components/UnauthLayout';
import AuthLayout from 'modules/shared/components/AuthLayout';

import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { APP_ROUTES, ENTRY_APP_PATH } from './routes';
import connect, { ConnectProps } from './connect';
import { useGlobalSubscriptions } from './useGlobalSubscriptions';

import styles from './styles.module.scss';
import { setShowGlobalSnackbar } from './globals';

export type MainProps = ConnectProps;

const Main = ({ isLoggedIn, onAppInit, isInitialized, isLoading }: MainProps) => {
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    setShowGlobalSnackbar(enqueueSnackbar);
    onAppInit(enqueueSnackbar);
  }, [onAppInit, enqueueSnackbar]);

  useGlobalSubscriptions(!isLoggedIn);

  if (!isInitialized) {
    return (
      <div className={styles.loading}>
        <CircularProgress size={50} />
      </div>
    );
  }
  const Layout = isLoggedIn ? AuthLayout : UnauthLayout;

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loading}>
          <CircularProgress size={50} />
        </div>
      )}
      <Layout>
        <Switch>
          {APP_ROUTES.map((route) => (
            <Route key={route.path} exact={route.exact} path={route.path} component={route.component} />
          ))}
          <Redirect path="*" to={isLoggedIn ? ENTRY_APP_PATH : '/login'} />
        </Switch>
      </Layout>
    </div>
  );
};

export default connect(Main);
