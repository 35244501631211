import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useCallback } from 'react';
import { PUBLISH_MAPPINGS } from '../gql';
import { processSteps } from '../tools';
import { Step } from '../types';

export const usePublishSteps = ({ connection, projectId }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [publishFn, { loading }] = useMutation(PUBLISH_MAPPINGS, {
    update(cache, { data: { publishMappings } }) {
      cache.modify({
        fields: {
          mappingsHistory(existing) {
            return [publishMappings, ...existing];
          },
        },
      });
    },
  });

  const publish = useCallback(
    (config: string, templateId: string) => {
      return publishFn({
        variables: {
          connection,
          dtTemplateId: templateId,
          projectId,
          config,
        },
      }).catch((error) => {
        enqueueSnackbar(error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [publishFn, enqueueSnackbar, connection, projectId],
  );

  const handlePublish = useCallback(
    async (steps: Step[], selectedStep: string, templateId: string) => {
      try {
        const { config, mappings } = processSteps(steps);

        return await publish(JSON.stringify({ config, mappings, steps, selectedStep }), templateId);
      } catch (e) {
        enqueueSnackbar((e as Error).message, BASE_ERROR_NOTIFICATION_OPTIONS);
      }
    },
    [enqueueSnackbar, publish],
  );

  return {
    publish: handlePublish,
    publishing: loading,
  };
};
