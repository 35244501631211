import { gql } from '@apollo/client';

export const CREATE_FLOW_CUSTOMIZE = gql`
  mutation CreateFlowCustomize($flowCustomize: FlowCustomizeDto!, $connection: String!) {
    createFlowCustomize(flow_customize: $flowCustomize, connection: $connection) {
      bodyFields
      headers
    }
  }
`;

export const UPDATE_FLOW_CUSTOMIZE = gql`
  mutation UpdateFlowCustomize($flowCustomize: FlowCustomizeDto!, $flowCustomizeId: String!, $connection: String!) {
    updateFlowCustomize(flow_customize: $flowCustomize, flow_customize_id: $flowCustomizeId, connection: $connection) {
      bodyFields
      headers
    }
  }
`;
