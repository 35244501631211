export enum Types {
  Mid = 'mid',
  Concat = 'concat',
  Replace = 'replace',
  Split = 'split',
  Combine = 'combine',
  Insert = 'insert',
  Case = 'case',
  Right = 'right',
  Add = 'add',
  Subtract = 'subtract',
  Divide = 'divide',
  Multiply = 'multiply',
  Round = 'round',
}

export const TypesList = Object.entries(Types);

export const TypesListOptions = TypesList.map(([label, value]) => ({ label, value }));

export const INPUT = 'input';
export const OUTPUT = 'output';
export const EDITABLE_COLUMNS = 'editable-columns';
export const TRANSFORMATION = 'transformation';
export const COMBINE_COLUMN = 'combine-value';
export const startConnectionDelimeter = '-start-';
export const endConnectionDelimeter = '-end-';

export const MAX_SCALE = 10;
export const MIN_SCALE = 0.5;
export const DEFAULT_CANVAS_SIZE = 5000;
export const INITIAL_BOARD_CONFIG = { scale: 6, x: 2000, y: 2000 };

export enum StepType {
  TRANSFORM = 'transformation',
  BLEND = 'blend',
  COMBINE = 'combine',
  ADD_COLUMNS = 'add',
  DELETE_COLUMNS = 'delete',
}

export const StepNames = {
  [StepType.TRANSFORM]: 'Transformation',
  [StepType.BLEND]: 'Blend',
  [StepType.ADD_COLUMNS]: 'Add Columns',
  [StepType.DELETE_COLUMNS]: 'Delete Columns',
  [StepType.COMBINE]: 'Combine',
};

export const EditableSteps = [StepType.BLEND];

export const INPUT_SHAPE_WIDTH = 250;
export const INPUT_SHAPE_HEIGHT = 300;
