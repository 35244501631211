import * as React from 'react';
import { TableCell, TableHead as MuiTableHead, TableRow } from '@mui/material';
import { TableColumnProps } from '..';

interface Props {
  columns: TableColumnProps[];
}
const TableHead: React.FC<Props> = ({ columns }) => {
  return (
    <MuiTableHead>
      <TableRow>
        {columns.map((column) => (
          <TableCell
            key={column.name}
            style={{
              borderTop: '1px solid rgba(224, 224, 224, 1)',
              ...column.style,
            }}
          >
            {column.name}
          </TableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default React.memo(TableHead);
