import { memo } from 'react';

import DashboardUI from 'modules/new-dashboard';

const NewDashboard = () => {
  return (
    <>
      <DashboardUI />
    </>
  );
};

export default memo(NewDashboard);
