import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    marginRight: 20,
  },
  flex: {
    flex: 1,
  },
  itemContainer: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    position: 'relative',
    boxShadow: '0 2px 8px 0 #8f8f8f',
    borderRadius: 4,
    overflow: 'hidden',
    padding: 4,
    '& > *': {
      flex: 1,
    },
  },
  selection: {
    width: 525,
    float: 'right',
  },

  container: {
    flex: 1,
    display: 'flex',
    backgroundColor: '#ffffff',
  },
  stepper: {
    flex: '0 0 200px',
    padding: 10,
    display: 'flex',
  },
  content: {
    flex: 1,
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
