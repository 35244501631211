import { gql } from '@apollo/client';

export const CREATE_DATA_STORE_CONNECTION = gql`
  mutation CreateDataStoresConnection(
    $connection: String!
    $projectId: String!
    $name: String!
    $type: String!
    $config: String!
  ) {
    createDataStoresConnection(
      connection: $connection
      project_id: $projectId
      name: $name
      type: $type
      config: $config
    ) {
      connectionId
      name
      type
      config
    }
  }
`;

export const UPDATE_DATA_STORE_CONNECTION = gql`
  mutation UpdateDataStoresConnection(
    $connection: String!
    $projectId: String!
    $connectionId: String!
    $name: String!
    $type: String!
    $config: String!
  ) {
    updateDataStoresConnection(
      connection: $connection
      project_id: $projectId
      connection_id: $connectionId
      name: $name
      type: $type
      config: $config
    ) {
      connectionId
      name
      type
      config
    }
  }
`;

export const DELETE_DATA_STORE_CONNECTION = gql`
  mutation DeleteDataStoresConnection($connection: String!, $projectId: String!, $dataStoreconnection: String!) {
    deleteDataStoresConnection(
      connection: $connection
      project_id: $projectId
      data_store_connection: $dataStoreconnection
    )
  }
`;

export const DELETE_SYNC_PATH = gql`
  mutation DeleteSyncPath($connection: String!, $pathId: String!) {
    deleteSyncPath(connection: $connection, path_id: $pathId)
  }
`;

export const CREATE_SYNC_PATHES = gql`
  mutation createSyncPathes(
    $connection: String!
    $projectId: String!
    $dataStoreConnection: String!
    $type: String!
    $name: String!
    $filter: String!
    $unzip: Boolean!
    $loadDeltaLake: Boolean!
    $pathes: [SyncPathInput!]!
    $schedulerType: String!
    $schedulerLabel: String!
    $schedulerPattern: String!
  ) {
    createSyncPathes(
      connection: $connection
      project_id: $projectId
      type: $type
      name: $name
      filter: $filter
      unzip: $unzip
      load_delta_lake: $loadDeltaLake
      data_store_connection: $dataStoreConnection
      pathes: $pathes
      scheduler_type: $schedulerType
      scheduler_label: $schedulerLabel
      scheduler_pattern: $schedulerPattern
    ) {
      pathId
      path
      isFile
      name
      dataStoreConnectionId
      scheduler {
        label
        type
        pattern
      }
    }
  }
`;

export const UPDATE_SYNC_PATH_SCHEDULER = gql`
  mutation updateSyncPathScheduler(
    $connection: String!
    $pathId: String!
    $name: String!
    $dataStoreConnection: String!
    $filter: String!
    $unzip: Boolean!
    $loadDeltaLake: Boolean!
    $schedulerType: String!
    $schedulerLabel: String!
    $schedulerPattern: String!
  ) {
    updateSyncPathScheduler(
      connection: $connection
      path_id: $pathId
      name: $name
      data_store_connection: $dataStoreConnection
      filter: $filter
      unzip: $unzip
      load_delta_lake: $loadDeltaLake
      scheduler_type: $schedulerType
      scheduler_label: $schedulerLabel
      scheduler_pattern: $schedulerPattern
    ) {
      pathId
      path
      isFile
      dataStoreConnectionId
      name
      scheduler {
        label
        type
        pattern
      }
    }
  }
`;
