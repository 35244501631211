import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

import { BASE_SUCCESS_NOTIFICATION_OPTIONS } from 'project-constants';
import { history } from 'configure/browserHistory';

import { changePassword as changePasswordApi } from 'modules/rest/api/auth';

export const useChangePassword = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const changePassword = useCallback(
    (token: string, password: string, confirmPassword: string) => {
      if (password !== confirmPassword) {
        return setError('Password does not match');
      }

      setLoading(true);

      return changePasswordApi(token, password)
        .then(() => {
          enqueueSnackbar(
            'Your password has been changed. Please log in using new password',
            BASE_SUCCESS_NOTIFICATION_OPTIONS,
          );
          history.replace('/home');
        })
        .catch(({ response }) => {
          const message = Array.isArray(response.data.message)
            ? response.data.message.join('. ')
            : response.data.message;

          setError(message);
        })
        .finally(() => setLoading(false));
    },
    [enqueueSnackbar],
  );

  return {
    changePassword,
    loading,
    error,
    setError,
  };
};
