import * as React from 'react';
import { Grid, TextField as MuiTextField, BaseTextFieldProps, Typography } from '@mui/material';

interface Props extends BaseTextFieldProps {
  isRequired?: boolean;
  onChange?: (event: any) => void;
}
const TextField: React.FC<Props> = ({ variant, style, fullWidth, label, isRequired, ...otherProps }) => {
  return (
    <>
      {label ? (
        <Grid container style={{ marginBottom: 5 }}>
          <Typography variant="body2">
            {label} {isRequired && <span style={{ color: 'red' }}>(*)</span>}
          </Typography>
        </Grid>
      ) : null}
      <MuiTextField
        variant={variant || 'outlined'}
        style={{ ...style }}
        fullWidth={fullWidth || true}
        {...otherProps}
      />
    </>
  );
};

export default TextField;
