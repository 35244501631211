import { replace } from 'connected-react-router';
import Cookies from 'js-cookie';
import Storage from 'modules/storage';

import { client } from 'modules/gql';
import msalInstance, { oauthEnabled } from 'configure/azure-ad-b2c';
import * as actions from '../actions';

export const logout = () => async (dispatch) => {
  Storage.removeAuthTokens();

  await msalInstance.logoutRedirect();
  client.resetStore();

  dispatch(actions.authLogout());

  dispatch(replace(''));

  if (oauthEnabled) {
    // TODO AUTH rework
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  }
};
