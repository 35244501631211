/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useApolloClient, useSubscription } from '@apollo/client';
import { Ref, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { authSelectors } from 'modules/auth';
import { Button } from '@material-ui/core';
import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';

import { BASE_ERROR_NOTIFICATION_OPTIONS, BASE_INFO_NOTIFICATION_OPTIONS } from 'project-constants';
import { ProjectsContext } from 'modules/shared/contexts/ProjectsContext';
import { DataGrid } from 'devextreme-react';
import { ENABLE_STATE_ID } from '../constants';
import {
  LOAD_FLOWS,
  CHANGE_FLOW_SUBSCRIPTION,
  REMOVE_FLOW_SUBSCRIPTION,
  REGISTER_FLOW_SUBSCRIPTION,
  DELETE_FLOW,
  UPDATE_FLOW_SUBSCRIPTION,
} from '../gql';
import { LoadFlowsResponse, Flow } from '../types';
import { compareObjects } from '../utils';
import { FlagsContext } from '../context/FlagsContext/context';

type Type = {
  [key: string]: string | number | undefined;
};

export const useFlowsForGrid = ({ connection, projectId, categoryId, statusId, stateId }) => {
  const client = useApolloClient();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const flags = useContext(FlagsContext);
  const snakbarsKeys = useRef<Type>({
    remove: '',
    register: '',
    update: '',
  });

  const { projects } = useContext(ProjectsContext);
  const userId = useSelector(authSelectors.getUserId);

  const isActive = stateId ? stateId === ENABLE_STATE_ID : null;

  const [activeFlows, setActiveFlows] = useState({});

  const load = useCallback(
    async ({ order, desc, limit, offset, search }) => {
      if (!connection) {
        return {
          data: [],
          totalCount: 0,
        };
      }

      const response = await client.query<LoadFlowsResponse>({
        query: LOAD_FLOWS,
        fetchPolicy: 'network-only',
        context: {
          requestId: 'load-flows-request',
          queryDeduplication: false,
        },
        variables: {
          search: search.trim(),
          connection,
          projectId,
          categoryId,
          statusId,
          isActive,
          order,
          desc,
          limit,
          offset,
        },
      });

      const { nodes, totalCount } = response.data?.flows || { nodes: [], totalCount: 0 };

      // fixes infinite reload for devexpress (when using global search)
      setTimeout(() => {
        setActiveFlows(nodes.reduce((acc, cur) => ({ ...acc, [cur.flowId]: cur }), {}));
      }, 0);

      return { data: nodes, totalCount };
    },
    [client, connection, projectId, categoryId, statusId, isActive],
  );

  const [deleteFlow] = useMutation(DELETE_FLOW);

  const remove = useCallback(
    (flowId: string) => {
      flags.deleteIds = [...flags.deleteIds, flowId];

      return deleteFlow({
        variables: {
          flowId,
          connection,
        },
      }).catch((err) => {
        flags.deleteIds = flags.deleteIds.filter((f) => f !== flowId);
        enqueueSnackbar(err.message, BASE_ERROR_NOTIFICATION_OPTIONS);
      });
    },
    [deleteFlow, enqueueSnackbar, flags, connection],
  );

  const dataStore = useMemo(
    () =>
      new CustomStore({
        key: 'flowId',
        load: (options) => {
          return load({
            order: options.sort && options.sort[0].selector,
            desc: options.sort && options.sort[0].desc,
            limit: options.take,
            offset: options.skip,
            search: Array.isArray(options.filter) ? options.filter[0][2] : '',
          });
        },
        remove,
        update: (key, data) => {
          return Promise.resolve(data);
        },
      }),
    [load, remove],
  );

  const dataSource = useMemo(
    () =>
      new DataSource({
        store: dataStore,
      }),
    [dataStore],
  );

  const datagrid: Ref<DataGrid> = useRef<DataGrid>(null);

  const updateFlowData = useCallback(
    (newFlowData: Flow, fieldsToUpdate?: Array<keyof Flow>) => {
      setActiveFlows((prev) => {
        return {
          ...prev,
          [newFlowData.flowId]: fieldsToUpdate?.length
            ? fieldsToUpdate.reduce(
                (acc, cur) => {
                  return {
                    ...acc,
                    [cur]: newFlowData[cur],
                  };
                },
                { ...prev[newFlowData.flowId] },
              )
            : newFlowData,
        };
      });
    },
    [dataSource],
  );

  useSubscription(REGISTER_FLOW_SUBSCRIPTION, {
    variables: { userId, connection },
    onSubscriptionData: ({ subscriptionData }) => {
      const registerFlow = subscriptionData.data.registerFlowSubscription as Flow;
      const states = ['off', 'on'];
      if (flags.registered === registerFlow.project.projectId) {
        dataSource.reload();
      } else if (
        ((!projectId && projects.find((p) => p.projectId === registerFlow.project.projectId)) ||
          projectId === registerFlow.project.projectId) &&
        (!categoryId || categoryId === registerFlow.category.categoryId) &&
        (!statusId || statusId === registerFlow.status.statusId) &&
        (!stateId || stateId === states[+registerFlow.isActive]) &&
        !flags.registered
      ) {
        closeSnackbar(snakbarsKeys.current.register);
        snakbarsKeys.current.register = enqueueSnackbar(`New flow "${registerFlow.flowName}" was added.`, {
          ...BASE_INFO_NOTIFICATION_OPTIONS,
          persist: true,
          action: () => {
            return (
              <>
                <Button
                  style={{ color: 'white' }}
                  onClick={() => {
                    closeSnackbar(snakbarsKeys.current.register);

                    dataSource.reload();
                  }}
                >
                  Refresh
                </Button>
              </>
            );
          },
        });
      }

      flags.registered = '';
    },
  });

  useSubscription(REMOVE_FLOW_SUBSCRIPTION, {
    variables: { connection },
    onSubscriptionData: ({ subscriptionData }) => {
      const removedFlow = subscriptionData.data.removeFlowSubscription;

      if (flags.deleteIds.includes(removedFlow.flowId)) {
        return dataSource.reload();
      }
      const index = datagrid.current!.instance.getRowIndexByKey(removedFlow.flowId);

      if (index >= 0) {
        closeSnackbar(snakbarsKeys.current.remove);
        snakbarsKeys.current.remove = enqueueSnackbar(`Flow "${removedFlow.flowName}" was deleted.`, {
          ...BASE_INFO_NOTIFICATION_OPTIONS,
          persist: true,
          action: () => {
            return (
              <>
                <Button
                  style={{ color: 'white' }}
                  onClick={() => {
                    closeSnackbar(snakbarsKeys.current.remove);

                    datagrid
                      .current!.instance.getDataSource()
                      .store()
                      .push([
                        {
                          type: 'remove',
                          key: removedFlow.flowId,
                        },
                      ]);
                  }}
                >
                  Refresh
                </Button>
              </>
            );
          },
        });
      }

      flags.deleteIds = flags.deleteIds.filter((f) => f !== removedFlow.flowId);
    },
  });

  useSubscription(UPDATE_FLOW_SUBSCRIPTION, {
    variables: { userId, connection },
    onSubscriptionData: ({ subscriptionData }) => {
      const { modifiedBy, ...updatedFlow } = subscriptionData.data.updateFlowSubscription;

      const index = datagrid.current!.instance.getRowIndexByKey(updatedFlow.flowId);
      const existingFlow: Flow = dataSource.items()[index];

      if (existingFlow) {
        if (!flags.updateIds.includes(updatedFlow.flowId)) {
          const isDataSame = compareObjects(existingFlow, updatedFlow);

          if (!isDataSame) {
            const message = `${modifiedBy} updated flow "${existingFlow.flowName}".`;
            closeSnackbar(snakbarsKeys.current.update);
            snakbarsKeys.current.update = enqueueSnackbar(message, {
              ...BASE_INFO_NOTIFICATION_OPTIONS,
              persist: true,
              action: () => {
                return (
                  <>
                    <Button
                      style={{ color: 'white' }}
                      onClick={() => {
                        closeSnackbar(snakbarsKeys.current.update);
                        updateFlowData(updatedFlow);
                      }}
                    >
                      Refresh
                    </Button>
                  </>
                );
              },
            });
          }
        } else {
          updateFlowData(updatedFlow);
        }
      }

      flags.updateIds = flags.updateIds.filter((f) => f !== updatedFlow.flowId);
    },
  });

  useSubscription(CHANGE_FLOW_SUBSCRIPTION, {
    variables: {
      userId,
      connection,
    },
    onSubscriptionData: ({ subscriptionData }) => {
      updateFlowData(subscriptionData.data.changeFlowSubscription, ['isActive', 'status', 'executedAt']);
    },
  });

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(snakbarsKeys.current).forEach((key) => {
        closeSnackbar(key);
      });
    };
  }, [closeSnackbar]);

  return {
    dataSource,
    remove,
    datagrid,
    updateFlowData,
    activeFlows,
  };
};
