import { DialogProps as MuiDialogProps, Dialog as MuiDialog } from '@mui/material';
import { Actions, Content, Title } from 'modules/shared/components/Dialog';
import { noop } from 'modules/shared/utils';
import * as React from 'react';

type DialogProps = MuiDialogProps & {
  title: string;
  content: React.ReactNode;
  actions?: React.ReactNode;
};

const Dialog: React.FC<DialogProps> = ({ title, content, onClose, actions, ...otherProps }) => {
  return (
    <MuiDialog {...otherProps}>
      <Title id="dialog-add-status" onClose={onClose ?? noop}>
        {title}
      </Title>
      <Content>{content}</Content>
      {actions ? <Actions>{actions}</Actions> : null}
    </MuiDialog>
  );
};

export default Dialog;
