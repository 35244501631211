import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';
import * as BorNeoParts from 'modules/shared/styles/global-classes';

export const useStyles = makeStyles(() => ({
  container: {
    ...BorNeoParts.container,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    outline: 'none',
  },
  gridContainer: {
    '@media (min-width: 600px)': {
      transform: 'translateY(-10%)',
    },
  },
  card: {
    opacity: 1,
    border: 0,
    margin: '30px 0',
    borderRadius: 6,
    color: 'rgba(0, 0, 0, 0.87)',
    background: BorNeoColors.primaryColor5,
    width: '100%',
    boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    overflow: 'hidden',
    transition: 'all 150ms linear',
  },
  cardAnimation: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
    transition: 'all 150ms linear',
  },
  cardHeader: {
    padding: '12px 20px',
    marginBottom: 20,
    background: BorNeoColors.accentColor9,
  },
  cardBody: {
    padding: '0 20px 12px',
  },
  cardTitle: {
    color: BorNeoColors.primaryColor4,
    marginTop: 0,
    minHeight: 'auto',
    fontWeight: 300,
    marginBottom: '3px',
    textDecoration: 'none',
    fontSize: '36px',
    textAlign: 'center',
  },
  inputIcon: {
    color: '#555',
  },
  error: {
    marginBottom: 17,
  },
}));
