import { CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { primaryColor5 } from 'modules/shared/styles/colors';
import { useStyles } from './styles';
import useDataStatsHook from './hooks/useConstraintsHook';
import Sunburst from './sunburst';
import { BASE_CATEGORIES_IDS } from '../hooks/useCategories';
import Bar from './bar';

const SPACING = 110;

const Statuses = ({ id }) => {
  const isSunburstChart = BASE_CATEGORIES_IDS.includes(id);

  const [size, setSize] = React.useState(0);
  const classes = useStyles();
  const { projectId, connection } = useProjectInfoFromSearch();
  const { loading, data } = useDataStatsHook(connection, projectId, id);

  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const { height, width } = containerRef.current?.getBoundingClientRect() || { height: 0, width: 0 };

    setSize(Math.max(Math.min(height - SPACING, width - SPACING), 0));
  }, []);

  const isEmptyData = !!(
    !loading && data?.children.every((c) => (!c.value || Number(c.value) === 0) && !c.children?.length)
  );

  const component =
    !loading && isSunburstChart ? (
      <Grid container justifyContent="center" flexDirection="column" className="sunburstChart">
        <Sunburst size={size} data={data} />
      </Grid>
    ) : (
      <Bar data={data} />
    );

  return (
    <div ref={containerRef} className={classes.container}>
      {isEmptyData ? (
        <div className={classes.center} style={{ background: primaryColor5 }}>
          <Typography>No Data to Display</Typography>
        </div>
      ) : (
        component
      )}

      {loading && (
        <div className={classes.center} style={{ background: primaryColor5 }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default React.memo(Statuses);
