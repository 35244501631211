import { appThunks } from 'modules/app';
import { Auth } from 'modules/rest';
import { RegisterData } from 'modules/rest/api/auth';

import Storage from 'modules/storage';

import * as actions from '../actions';

export const register = (userData: RegisterData) => async (dispatch) => {
  dispatch(actions.authRegisterStart());

  try {
    const {
      data: { accessToken, refreshToken, user },
    } = await Auth.register(userData);

    Storage.setAccessToken(accessToken);
    Storage.setRefreshToken(refreshToken);

    await dispatch(appThunks.preloadData());

    dispatch(
      actions.authRegisterSuccess({
        id: user.id,
        username: user.username,
        email: user.email,
        role: user.role,
        connections: user.connections,
        adminConnections: user.adminConnections,
      }),
    );
  } catch (error) {
    // @ts-ignore
    const errorMsg = error.response.data?.error || '';
    const [errorCode] = typeof errorMsg === 'string' ? errorMsg.split('|') : ['', ''];

    dispatch(actions.authRegisterFailure(errorCode ? ['Error occured'] : Object.values(errorMsg)));
  }
};
