import { VariantType } from 'notistack';
import * as actions from 'modules/app/actions';
import { store } from 'index';

export const fireNotiStack = (message: any, variant: VariantType) => {
  if (typeof message === 'string') {
    store.dispatch(actions.notificationEnqueue([{ message, variant }]));
    return;
  }
  store.dispatch(
    actions.notificationEnqueue(
      message.map((el: string) => ({
        message: el,
        variant,
      })),
    ),
  );
};

export const fireSuccessNotiStack = (message: string) => {
  fireNotiStack(message, 'success');
};
export const fireErrorNotiStack = (message: string) => {
  fireNotiStack(message, 'error');
};
export const fireErrorsNotiStack = (message: string[]) => {
  fireNotiStack(message, 'error');
};
