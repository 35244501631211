import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { Button, Grid, CircularProgress, Collapse } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import classnames from 'classnames';

import { history } from 'configure/browserHistory';
import Input from 'modules/shared/components/Input';

import { useForgotCredentials } from './hooks/useForgotCredentials';

import { useStyles } from './styles';

const ForgotCredentialsUI = () => {
  const classes = useStyles();
  const formRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const { resetPassword, loadingPassword, sendUsername, loadingUsername, error } = useForgotCredentials();

  const [email, setEmail] = useState('');
  const [animation, setAnimation] = useState('cardAnimation');

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation('');
    }, 50); // since modal opens on route change we need to add a little delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const closeOnBlur = useCallback(({ target }) => {
    if (!formRef.current?.contains(target)) {
      history.replace('/home');
    }
  }, []);

  return (
    <div role="button" tabIndex={0} onKeyPress={() => {}} className={classes.container} onClick={closeOnBlur}>
      <Grid container justify="center" className={classes.gridContainer}>
        <Grid item xs={12} sm={6} md={4}>
          <div ref={formRef} className={classnames(classes.card, classes[animation])}>
            <div className={classes.cardHeader}>
              <h2 className={classes.cardTitle}>Forgot Credentials</h2>
            </div>
            <div className={classes.cardBody}>
              <Input
                error={!!error}
                labelText="Email address"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  type: 'email',
                  required: true,
                  onChange: (event) => setEmail(event.target.value),
                }}
              />
              <Collapse in={!!error}>
                <Alert severity="error" className={classes.error}>
                  {error}
                </Alert>
              </Collapse>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={() => {
                      resetPassword(email);
                    }}
                  >
                    {loadingPassword ? <CircularProgress size={20} /> : 'Reset password'}
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={() => {
                      sendUsername(email);
                    }}
                  >
                    {loadingUsername ? <CircularProgress size={20} /> : 'Send username'}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(ForgotCredentialsUI);
