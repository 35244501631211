/* eslint-disable import/no-extraneous-dependencies */
import { WebSocketLink } from '@apollo/client/link/ws';
import Storage from 'modules/storage';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export const createWsLink = () => {
  const protocol = process.env.REACT_APP_HTTPS === 'https' ? 'wss' : 'ws';

  const wsClient = new SubscriptionClient(`${protocol}://${process.env.REACT_APP_API_HOST}/graphql`, {
    reconnect: true,
    connectionParams: () => ({
      headers: {
        authorization: Storage.getAccessToken() ? `Bearer ${Storage.getAccessToken()}` : '',
        'x-id-token': Storage.getIdToken() || '',
      },
    }),
  });

  const wsLink = new WebSocketLink(wsClient);

  return wsLink;
};
