import { SelectProps, Select as MuiSelect, MenuItem, Grid, Typography } from '@mui/material';
import * as React from 'react';

interface Props extends SelectProps {
  keyValue?: string;
  keyName?: string;
  options: any;
  isRequired?: boolean;
}
const Select: React.FC<Props> = ({ keyValue, keyName, options, fullWidth, label, isRequired, ...otherProps }) => {
  return (
    <>
      {label ? (
        <Grid container style={{ marginBottom: 5 }}>
          <Typography variant="body2">
            {label} {isRequired && <span style={{ color: 'red' }}>(*)</span>}
          </Typography>
        </Grid>
      ) : null}
      <MuiSelect {...otherProps} fullWidth={fullWidth ?? true}>
        {options.map((el: any) => (
          <MenuItem key={el[keyValue || 'id']} value={el[keyValue || 'id']}>
            {el[keyName || 'name']}
          </MenuItem>
        ))}
      </MuiSelect>
    </>
  );
};

export default Select;
