import { memo } from 'react';
import UsersTableUI from 'modules/users/table-ui';

import { useStyles } from './styles';

const Users = () => {
  const classes = useStyles();

  return (
    <div className={classes.page}>
      <UsersTableUI />
    </div>
  );
};

export default memo(Users);
