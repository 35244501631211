import { memo } from 'react';
import classnames from 'classnames';
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';
import { useStyles } from './styles';
import GoToDataSpaceApp from '../go-to-data-space-app';

const ApplicationEndpointsUI = () => {
  const classes = useStyles();

  const tableData = [
    {
      application: 'https://workpacks.com/wp-content/uploads/2019/10/logo-wp.png',
      application_type: 'Work Packaging',
      sub_type: 'In Production',
      responsible_role: 'AWP Coordinator',
      data_io: {
        registered: '',
        validated: '',
        production: '',
      },
    },
    {
      application: 'https://primakon.com/wp-content/uploads/2020/11/Oracle-Primavera-Logo.png',
      application_type: 'Scheduling',
      sub_type: 'In Production',
      responsible_role: 'Project Controls',
      data_io: {
        registered: '',
        validated: '',
        production: '',
      },
    },
    {
      application:
        'https://static.crozdesk.com/web_app_library/providers/logos/000/' +
        '004/045/original/aconex-1559230697-logo.png?1559230697',
      application_type: 'Document Management',
      sub_type: '',
      responsible_role: 'Document Control',
      data_io: {
        registered: '',
        validated: '',
        production: '',
      },
    },
  ];

  return (
    <>
      <div className={classes.buttons}>
        <GoToDataSpaceApp />
      </div>
      <TableContainer className={classes.container} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell className={classnames(classes.groups, classes.actionsCellWrap)} align="center">
                Data I/O Set-Up
              </TableCell>
            </TableRow>
          </TableHead>
          <TableHead>
            <TableRow>
              <TableCell align="center">Application</TableCell>
              <TableCell align="center">Application Type</TableCell>
              <TableCell align="center">Sub Type</TableCell>
              <TableCell align="center">Responsible Role</TableCell>
              <TableCell align="center" className={classnames(classes.actionsCellWrap)}>
                <div className={classes.actionsCell}>
                  <div>Registered</div>
                  <div>Validated</div>
                  <div>Production</div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => {
              return (
                <TableRow key={row.application}>
                  <TableCell align="center">
                    <img className={classes.tableImg} alt={row.application_type} src={row.application} />
                  </TableCell>
                  <TableCell align="center">{row.application_type}</TableCell>
                  <TableCell align="center">{row.responsible_role}</TableCell>
                  <TableCell align="center">{row.sub_type}</TableCell>
                  <TableCell className={classnames(classes.actionsCellWrap)} align="center">
                    <div className={classes.actionsCell}>
                      <div>{row.data_io.registered}</div>
                      <div>{row.data_io.validated}</div>
                      <div>{row.data_io.production}</div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default memo(ApplicationEndpointsUI);
