/* eslint-disable @typescript-eslint/naming-convention */
import { AreaDiscipline } from '../interfaces/area-discipline';
import AreaDisciplines from '../mocks/area_disciplines.json';
import Legends from '../mocks/legends.json';
import Colors from '../mocks/colors.json';

export const getAreaDisciplinesMiddleware = async (
  categoryId: string,
): Promise<{
  isSuccess: boolean;
  data: AreaDiscipline[];
}> => {
  try {
    const { area_disciplines } = AreaDisciplines;
    const { legends } = Legends;
    const { colors } = Colors;
    const areaDisciplineByCategory = area_disciplines.filter((el) => el.category_id === categoryId);
    return {
      isSuccess: true,
      data: areaDisciplineByCategory.map((item) => {
        const legend = legends.find((lg) => lg.id === item.legend_id) as any;
        if (legend) {
          legend.color = colors.find((color) => color.id === legend.color_id);
        }
        return {
          ...item,
          areaId: item.area_id,
          disciplineId: item.discipline_id,
          legend,
        };
      }) as any,
    };
  } catch (error) {
    return { isSuccess: false, data: [] };
  }
};
