import { memo, useRef, useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import { ZoomInMapTwoTone, ZoomOutMapTwoTone, FitScreen } from '@mui/icons-material';

import { useSearch } from 'modules/shared/hooks/url';
import RadialTree, { RadialTreeRef } from 'modules/shared/components/RadialTree';
import { useBuilderModalState } from 'modules/shared/hooks/base';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { Tooltip } from '@mui/material';
import { useAdminOrSuperUser } from 'modules/auth/tools';
import { useLoadCheckingData, useLoadDigitalthreadTypes } from '../hooks';

import {
  BoardType,
  CustomCheckingItemType,
  CustomCheckingItemTypeText,
  DATASOURCE_COLOR,
  CheckingDataItem,
  statusItems,
  CHECKING_VIEW_HANDLE_ID,
  CHECKING_EDIT_HANDLE_ID,
} from '../constants';
import RelationsModal from './RelationsModal';
import LegendBox from './LegendBox';
import {
  getCheckingDataAnnotationMargin,
  getCheckingDataArrows,
  getCheckingDataItemShape,
  getCheckingDataItemTooltip,
  getCheckingDataShapeColor,
  getCheckingDataShapeLvl,
  getCheckingDataTextColor,
  getCheckingDataVisibleHandles,
} from './utils';
import { CheckingDataHandlesSuperUser, CheckingDataHandlesBaseUser, RadialTreeCheckingItemConfig } from './constants';
import { useStyles } from './styles';

const CheckView = () => {
  const classes = useStyles();
  const { get, set } = useSearch();
  const { projectId, connection } = useProjectInfoFromSearch();
  const dtType = get('type');
  const viewLevel = get('boardType');

  const { loading: dtTypeLoading, digitalthreadTypes } = useLoadDigitalthreadTypes(connection);

  const dtTemplatesByType = digitalthreadTypes.find((item) => item.type === dtType)?.templates || [];

  const templateId = get('templateId') ? get('templateId') : dtTemplatesByType[0]?.templateId;
  const isCategory = viewLevel === BoardType.CATEGORY;
  const dtBoardId = isCategory ? dtType : templateId;

  const onChangeDt = (dtLevel) => {
    set({
      boardType: dtLevel,
    });
  };

  const { checkingData, loading } = useLoadCheckingData(connection, projectId, dtBoardId, isCategory);

  const diagramRef = useRef<RadialTreeRef>(null);

  const { modalConfig, handleModalOpen, handleModalClose } = useBuilderModalState();

  useEffect(() => {
    diagramRef.current?.centerLayout();
  }, [checkingData]);

  const editable = useAdminOrSuperUser(connection, projectId);

  return (
    <>
      <Grid container spacing={2}>
        <Grid className={classes.buttonContainer} item sm={12} md={6} style={{ paddingLeft: 0 }}>
          <div className={classes.dtCheckLegend}>Legend</div>
          {statusItems.map((lg) => (
            <LegendBox key={lg.status} status={lg.status} />
          ))}
          <LegendBox status={CustomCheckingItemTypeText[CustomCheckingItemType.DATA_SOURCE]} color={DATASOURCE_COLOR} />
        </Grid>

        <Grid item className={`${classes.buttonContainer} ${classes.buttonContainerRightSide}`} sm={12} md={6}>
          <Tooltip title="View category">
            <Button
              variant="contained"
              key="dt-category-board"
              id="dt-category-board"
              onClick={() => {
                onChangeDt(BoardType.CATEGORY);
              }}
              className={
                viewLevel === BoardType.CATEGORY
                  ? `${classes.dtSelectionButton}`
                  : `${classes.dtSelectionButtonDisabled}`
              }
            >
              <ZoomOutMapTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="View template">
            <Button
              variant="contained"
              key="dt-template-board"
              id="dt-template-board"
              onClick={() => {
                onChangeDt(BoardType.TEMPLATE);
              }}
              className={
                viewLevel === '' || viewLevel === BoardType.TEMPLATE
                  ? `${classes.dtSelectionButton}`
                  : `${classes.dtSelectionButtonDisabled}`
              }
            >
              <ZoomInMapTwoTone />
            </Button>
          </Tooltip>
          <Tooltip title="Center layout">
            <Button
              variant="contained"
              key="dt-adapt-layout"
              id="dt-adapt-layout"
              onClick={() => {
                diagramRef.current?.centerLayout();
              }}
              data-title="Center layout"
              className={classes.dtSelectionButton}
            >
              <FitScreen />
            </Button>
          </Tooltip>
        </Grid>
      </Grid>

      <RelationsModal {...modalConfig} onClose={handleModalClose} connection={connection} projectId={projectId} />

      <RadialTree<CheckingDataItem>
        itemId="itemId"
        parentId="parentId"
        diagramRef={diagramRef}
        loading={loading || dtTypeLoading}
        items={checkingData}
        getShapeColor={getCheckingDataShapeColor}
        getTextColor={getCheckingDataTextColor}
        onHandleClick={(type, item) => {
          if (type === CHECKING_VIEW_HANDLE_ID) {
            if (item.lvl !== 1) {
              const dtTypeWithTemplates = digitalthreadTypes.find((el) => {
                return el.templates.some((t) => t.templateId === item.item);
              });

              set({
                boardType: BoardType.TEMPLATE,
                type: dtTypeWithTemplates?.type || dtType,
                templateId: item.item,
              });
            }
          }
          if (type === CHECKING_EDIT_HANDLE_ID) {
            handleModalOpen(item);
          }
        }}
        getLvl={getCheckingDataShapeLvl}
        itemConfigPerLvl={RadialTreeCheckingItemConfig}
        getVisibleHandles={getCheckingDataVisibleHandles}
        getTooltip={getCheckingDataItemTooltip(checkingData, isCategory)}
        getShape={getCheckingDataItemShape}
        getArrows={getCheckingDataArrows}
        getAnnotationMargin={getCheckingDataAnnotationMargin}
        userHandles={editable ? CheckingDataHandlesSuperUser : CheckingDataHandlesBaseUser}
      />
    </>
  );
};

export default memo(CheckView);
