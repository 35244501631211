import { makeStyles } from '@material-ui/core';
import * as BorNeoColors from 'modules/shared/styles/colors';

export const useStyles = makeStyles(() => ({
  item: {
    '&:not(:last-child)': {
      marginBottom: 15,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
  },
  img: {
    width: 36,
    height: 36,
    objectFit: 'cover',

    marginRight: 10,
  },
  title: {
    color: '#3C4858',
    fontSize: '1.125rem',
    lineHeight: 1.4,
    fontWeight: 300,
  },
  description: {
    marginLeft: 46,
    fontSize: '0.875rem',
    lineHeight: 1.5,
    fontWeight: 300,
    color: BorNeoColors.accentColor3,
  },
}));
