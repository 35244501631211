import * as React from 'react';

interface Props {
  color: string;
  className?: string;
  style?: React.CSSProperties;
}
const CircleColor: React.FC<Props> = ({ color, style, className }) => {
  return (
    <span
      className={className || ''}
      style={{
        width: 20,
        height: 20,
        borderRadius: 25,
        backgroundColor: color,
        ...style,
      }}
    />
  );
};

export default React.memo(CircleColor);
