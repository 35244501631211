import { InputLabel } from '@material-ui/core';
import ReactSelect from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useStyles } from './styles';

type Props<T> = {
  id: string;
  labelText?: string;
  value: Array<T>;
  options: {
    label: string;
    value: T;
  }[];
  isMulti?: boolean;
  isCreatable?: boolean;
  onCreateOption?: (val: string, id: string) => void;
  onChange?: (val: T[], id: string) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  noOptionsMessage?: () => string;
  disableClear?: boolean;
  required?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  placeholder?: string;
  multiValue?: React.CSSProperties;
  multiValueLabel?: React.CSSProperties;
  valueContainer?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
};

function Select<T>({
  id,
  options,
  value,
  isMulti,
  isCreatable,
  onCreateOption,
  onChange,
  labelText,
  disabled = false,
  className,
  loading,
  noOptionsMessage,
  disableClear,
  menuPosition,
  required,
  placeholder,
  multiValue,
  multiValueLabel,
  valueContainer,
  containerStyle,
}: Props<T>) {
  const selectedOptions = options.filter((opt) => value.includes(opt.value));
  const selected = isMulti ? selectedOptions : selectedOptions[0];
  const classes = useStyles();

  let Component = ReactSelect;
  if (isCreatable && onCreateOption && typeof onCreateOption === 'function') {
    Component = CreatableSelect;
  }

  const handleChange = (val) => {
    if (onChange && typeof onChange === 'function') {
      if (val === null) {
        onChange([], id);
      } else if (!isMulti) {
        onChange([val.value], id);
      } else {
        onChange(Array.isArray(val) ? val.map((el) => el.value) : val.value, id);
      }
    }
  };

  const handleCreateOption = (newValue) => {
    if (onCreateOption && typeof onCreateOption === 'function') {
      onCreateOption(newValue, id);
    }
  };

  const getTextColor = (provided, state) => {
    if (state.data?.color) {
      return {
        ...provided,
        backgroundColor: state.data?.color,
      };
    }
    return provided;
  };

  return (
    <div className={classes.container}>
      {labelText !== undefined ? (
        <InputLabel className={classes.label} htmlFor={id}>
          {labelText}
        </InputLabel>
      ) : null}
      <Component
        isMulti={isMulti}
        value={selected || ''}
        onChange={handleChange}
        options={options}
        onCreateOption={handleCreateOption}
        menuPortalTarget={document.body}
        menuPosition={menuPosition}
        isDisabled={disabled}
        className={className}
        isLoading={loading}
        isClearable={!disableClear}
        noOptionsMessage={noOptionsMessage}
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          valueContainer: (base) => ({ ...base, ...valueContainer }),
          placeholder: (base) => ({ ...base, fontSize: 13, margin: '2px 8px' }),
          multiValueLabel: (base) => ({ ...base, ...multiValueLabel }),
          container: (base) => ({ ...base, ...containerStyle }),
          multiValue: (base, state) => getTextColor({ ...base, ...multiValue }, state),
          control: (styles) => ({ ...styles, minHeight: 41 }),
        }}
        placeholder={placeholder}
      />
      {!disabled && (
        <input
          tabIndex={-1}
          autoComplete="off"
          className={classes.input}
          value={value.join('')}
          onChange={() => {}}
          required={required}
        />
      )}
    </div>
  );
}

Select.defaultProps = {
  labelText: undefined,
  isMulti: false,
  disabled: false,
  loading: false,
  isCreatable: false,
  onCreateOption: null,
  onChange: null,
  className: '',
  noOptionsMessage: undefined,
  disableClear: false,
  menuPosition: 'fixed',
  required: false,
  multiValue: {},
  multiValueLabel: {},
  valueContainer: {},
  containerStyle: {},
};

export default Select;
