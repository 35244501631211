/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-nested-ternary */
/* eslint-disable func-names */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-return-assign */
import * as d3 from 'd3';
import React, { memo } from 'react';
import { useStyles } from './styles';

interface Data {
  name: string;
  value?: number;
}

const Sunburst = ({ data, size }) => {
  const classes = useStyles();
  const svgRef = React.useRef<SVGSVGElement>(null);

  const colorPicker = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, data.children.length + 1));

  const radius = size / 6;
  const dataPartition = (_data) => {
    const root = d3
      .hierarchy({
        ..._data,
      })
      .sum((d) => Number(d.value))
      .sort((a: any, b: any) => Number(b.value) - Number(a.value));
    return d3.partition().size([2 * Math.PI, root.height + 1])(root);
  };

  const format = d3.format(',d');

  const arcVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
  };

  const labelVisible = (d) => {
    return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > 0.03;
  };

  const getText = (text: string) => {
    return text.length > 18 ? `${text.slice(0, 15)}...` : text;
  };

  const getTextTransform = React.useCallback(
    (d: d3.HierarchyRectangularNode<Data>) => {
      const x = (((d.x0 + d.x1) / 2) * 180) / Math.PI;
      const y = ((d.y0 + d.y1) / 2) * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    },
    [radius],
  );

  const handleClicked = React.useCallback(
    (p, parentCircle, dataRoot, path, arc, label, g) => {
      parentCircle.datum(p?.parent || dataRoot);
      if (p) {
        dataRoot.each(
          (d: any) =>
            (d.target = {
              x0: Math.max(0, Math.min(1, (d.x0 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              x1: Math.max(0, Math.min(1, (d.x1 - p.x0) / (p.x1 - p.x0))) * 2 * Math.PI,
              y0: Math.max(0, d.y0 - p.depth),
              y1: Math.max(0, d.y1 - p.depth),
            }),
        );
      }

      const t = g.transition().duration(750);

      path
        .transition(t)
        .tween('data', (d) => {
          const i = d3.interpolate(d.current, d.target);
          return (_t) => (d.current = i(_t));
        })
        .filter(function (this, d) {
          return +this.getAttribute('fill-opacity') || arcVisible(d.target);
        })
        .attr('fill-opacity', (d) => (arcVisible(d.target) ? (d.children ? 0.6 : 0.4) : 0))
        .attr('pointer-events', (d) => (arcVisible(d.target) ? 'auto' : 'none'))
        .attrTween('d', (d) => () => arc(d.current));

      label
        .filter(function (this, d) {
          return +this.getAttribute('fill-opacity') || labelVisible(d.target);
        })
        .transition(t)
        .attr('fill-opacity', (d) => +labelVisible(d.target))
        .attrTween('transform', (d) => () => getTextTransform(d.current));
    },
    [getTextTransform],
  );

  const drawingChart = React.useCallback(
    (dataSunburst, arc, tooltip, g) => {
      const showTooltip = (d, i) => {
        tooltip
          .style('left', `${d.pageX + 10}px`)
          .style('top', `${d.pageY - 25}px`)
          .style('display', 'inline-block')
          .html(`${i.data.name}: ${format(i.value)}`);
      };

      const hideTooltip = () => {
        tooltip.style('display', 'none');
      };
      const dataRoot = dataPartition(dataSunburst);
      dataRoot.each((d: any) => (d.current = d));

      const path = g
        .append('g')
        .selectAll('path')
        .data(dataRoot.descendants().slice(1))
        .join('path')
        .attr('fill', (d) => d.data.color || colorPicker(d.data.name))
        .attr('fill-opacity', (d) => (arcVisible(d.current) ? (d.children ? 0.6 : 0.4) : 0))
        .attr('pointer-events', (d) => (arcVisible(d.current) ? 'auto' : 'none'))
        .attr('d', (d) => arc(d.current));
      path
        .filter((d) => d.children)
        .style('cursor', 'pointer')
        .on('click', (_, i) => handleClicked(i, parentCircle, dataRoot, path, arc, label, g));

      path.on('mousemove', showTooltip).on('mouseout', hideTooltip);

      const label = g
        .append('g')
        .attr('pointer-events', 'none')
        .attr('text-anchor', 'middle')
        .style('user-select', 'none')
        .selectAll('text')
        .data(dataRoot.descendants().slice(1))
        .join('text')
        .attr('dy', '0.35em')
        .attr('fill-opacity', (d) => +labelVisible(d.current))
        .attr('transform', (d) => getTextTransform(d))
        .text((d) => getText(d.data.name || ''));

      const parentCircle = g
        .append('circle')
        .datum(dataRoot)
        .attr('r', radius)
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .style('cursor', 'pointer')
        .on('click', (_, i) => handleClicked(i, parentCircle, dataRoot, path, arc, label, g));
    },
    [radius, format, colorPicker, handleClicked, getTextTransform],
  );

  const buildChart = React.useCallback(() => {
    const svg = d3.select(svgRef.current).attr('viewBox', [0, 0, size, size]).style('font', '10px sans-serif');
    svg.selectAll('g').remove();
    const g = svg
      .append('g')
      .attr('id', 'parent')
      .attr('transform', `translate(${size / 2},${size / 2})`);
    const tooltip = d3.select('.sunburstChart').append('div').attr('class', classes.tooltip);
    const arc = d3
      .arc()
      .startAngle((d: any) => d.x0)
      .endAngle((d: any) => d.x1)
      .padAngle((d: any) => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius((d: any) => d.y0 * radius)
      .outerRadius((d: any) => Math.max(d.y0 * radius, d.y1 * radius - 1));
    drawingChart(data, arc, tooltip, g);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classes.tooltip, colorPicker, drawingChart]);

  React.useEffect(() => {
    buildChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data.children)]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <svg ref={svgRef} style={{ width: size, height: size }} />
    </div>
  );
};

export default memo(Sunburst);
