import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import CircleColor from '../components/CircleColor';
import Table, { TableColumnProps, TableRowProps } from '../components/Table';
import { useAreaDisciplines } from '../hooks/useAreaDisciplines';
import { useAreas } from '../hooks/useAreas';
import { useDisciplines } from '../hooks/useDisciplines';
import { useStyles } from './styles';

interface Props {
  categoryId: string;
  legendIds: string[];
}
const Readiness: React.FC<Props> = ({ categoryId, legendIds }) => {
  const classes = useStyles();
  const { areas } = useAreas(categoryId);
  const { disciplines } = useDisciplines(categoryId);
  const { areaDisciplines } = useAreaDisciplines(categoryId);

  const [columns, setColumns] = React.useState<TableColumnProps[]>([]);
  const [rows, setRows] = React.useState<TableRowProps[]>([]);

  React.useEffect(() => {
    const newColumns: TableColumnProps[] = disciplines.map((discipline) => {
      return {
        name: discipline.name,
        field: discipline.id,
      };
    });
    newColumns.unshift({
      name: 'AREA',
      field: 'area',
    });
    setColumns([...newColumns]);
  }, [disciplines]);

  React.useEffect(() => {
    if (!areas.length || !disciplines.length || !areaDisciplines.length) {
      return;
    }
    const newRows = areas.map((area) => {
      const keyDisciplines = disciplines.map((el) => el.id);
      const itemReturn: { [x: string]: any } = {
        ...area,
        area: area.name,
      };
      const disciplinesByArea = areaDisciplines.filter((item) => item.areaId === area.id);
      keyDisciplines.forEach((key) => {
        const itemMatch = disciplinesByArea.find((el) => el.disciplineId === key);
        const isMatchLegend = !!(!itemMatch || !legendIds.length || legendIds.includes(itemMatch?.legendId));
        itemReturn[key] = (
          <CircleColor
            color={itemMatch ? itemMatch.legend?.color?.color || '' : 'rgb(226 226 226)'}
            style={{
              display: 'inline-block',
            }}
            className={`${classes.discipline} ${isMatchLegend ? '' : ' semi-opacity'}`}
          />
        );
      });
      return itemReturn;
    });
    setRows([...newRows]);
  }, [areaDisciplines, disciplines, areas, legendIds, classes.discipline]);
  return (
    <Grid container>
      <Grid container justifyContent="center" style={{ marginBottom: 15 }}>
        <Typography variant="h6">DISCIPLINES</Typography>
      </Grid>
      <Grid container>
        <Table columns={columns} rows={rows} />
      </Grid>
    </Grid>
  );
};

export default React.memo(Readiness);
