import { useCallback, useRef } from 'react';
import Storage from 'modules/storage';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import { v4 } from 'uuid';

import { format } from 'date-fns';

const checkIfFilesIsNotReadyOrUploaded = (files) => files.some((file) => !['1', '2'].includes(file.statusCode));

export const useUploaderHooks = ({
  data = {},
  getCustomData = () => ({}),
  connection,
  projectId,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const instance = useRef<UploaderComponent | null>(null);

  const uploadId = useRef(null);
  const historyFolder = useRef('');

  const setHeaders = useCallback((args) => {
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${Storage.getAccessToken()}`);
    args.currentRequest.setRequestHeader('x-refresh-token', Storage.getRefreshToken());
    args.currentRequest.setRequestHeader('x-id-token', Storage.getIdToken());
  }, []);

  const setCustomFormData = useCallback(
    (args) => {
      args.customFormData.push({ connection });
      args.customFormData.push({ projectId });

      Object.entries(data).forEach(([key, val]) => {
        args.customFormData.push({ [key]: val });
      });

      Object.entries(getCustomData()).forEach(([key, val]) => {
        args.customFormData.push({ [key]: val });
      });

      args.customFormData.push({ uploadId: uploadId.current });
      args.customFormData.push({ historyFolder: historyFolder.current });
    },
    [data, connection, projectId, getCustomData],
  );

  const handleBeforeUpload = useCallback(
    (args) => {
      if (instance.current?.filesData.every((f) => f.statusCode === '1')) {
        historyFolder.current = format(new Date(), 'yyyyMMdd_HHmmss');
      }
      uploadId.current = uploadId.current ?? v4();
      setCustomFormData(args);
    },
    [setCustomFormData],
  );

  const handleUploading = useCallback(
    (args) => {
      setHeaders(args);
      setCustomFormData(args);
    },
    [setCustomFormData, setHeaders],
  );

  const handleRemoving = useCallback(
    (args) => {
      args.postRawFile = false;
      if (args.filesData[0].statusCode === '1') {
        return;
      }
      if (args.currentRequest) {
        setHeaders(args);
        setCustomFormData(args);
        if (args.filesData[0].statusCode !== '2') {
          args.customFormData.push({ partialUploaded: true });
        }
      } else {
        args.cancel = true;

        // @ts-ignore
        instance.current?.removeUploadedFile(
          args.filesData[0],
          { customFormData: args.customFormData, filesData: args.filesData },
          false,
        );
      }
    },
    [setCustomFormData, setHeaders],
  );

  const handleCanceling = useCallback(
    (args) => {
      setCustomFormData(args);
      args.customFormData.push({ partialUploaded: true });
      args.customFormData.push({ authorization: `Bearer ${Storage.getAccessToken()}` });
      args.customFormData.push({ 'x-refresh-token': Storage.getRefreshToken() });
      args.customFormData.push({ 'x-id-token': Storage.getIdToken() });
    },
    [setCustomFormData],
  );

  const handleClearing = useCallback(
    (args) => {
      if (checkIfFilesIsNotReadyOrUploaded(args.filesData)) {
        enqueueSnackbar(
          'Some files are not uploaded yet. Please discard uploading or delete not finished files',
          BASE_ERROR_NOTIFICATION_OPTIONS,
        );
        args.cancel = true;
      }
    },
    [enqueueSnackbar],
  );

  const handleSuccess = useCallback(() => {
    onSuccess();
  }, [onSuccess]);

  const checkFiles = useCallback(
    (cb) => {
      if (checkIfFilesIsNotReadyOrUploaded(instance.current?.filesData || [])) {
        enqueueSnackbar(
          'Some files are not uploaded yet. Please discard uploading or delete not finished files',
          BASE_ERROR_NOTIFICATION_OPTIONS,
        );
      } else {
        cb();
      }
    },
    [enqueueSnackbar],
  );

  const handleClose = useCallback(() => {
    checkFiles(onClose);
  }, [onClose, checkFiles]);

  return {
    instance,
    handleUploading,
    handleRemoving,
    handleCanceling,
    handleClearing,
    handleSuccess,
    handleClose,
    handleBeforeUpload,
    checkFiles,
  };
};
