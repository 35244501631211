import { AxiosRequestConfig } from 'axios';
import Storage from 'modules/storage';

export default (config: AxiosRequestConfig) => {
  const accessToken = Storage.getAccessToken();
  const idToken = Storage.getIdToken();

  if (accessToken) {
    const headers = config.headers || {};

    return {
      ...config,
      originalReqConfig: config,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'x-id-token': idToken,
      },
    };
  }

  return config;
};
