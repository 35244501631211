import { gql } from '@apollo/client';

export const CONNECTION_DEACTIVATION_SUBSCRIPTION = gql`
  subscription ConnectionDeactivationSubscription {
    connectionDeactivationSubscription {
      name
      deleted
    }
  }
`;
