import { memo } from 'react';
import { Paper, TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@material-ui/core';
import { useStyles } from './styles';

const DataSpacesLogUI = () => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">Project</TableCell>
            <TableCell align="center">Category</TableCell>
            <TableCell align="center">Data Connection</TableCell>
            <TableCell align="center">Log Event</TableCell>
            <TableCell align="center">Date - Time</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Issues</TableCell>
            <TableCell align="center">Remarks</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default memo(DataSpacesLogUI);
