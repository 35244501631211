import { gql } from '@apollo/client';

export const UPDATE_FLOW_SOLUTION_SHAPE_DATA = gql`
  mutation UpdateFlowSolutionShapeData(
    $boardId: String!
    $shapeId: String!
    $title: String
    $metadata: String
    $connection: String!
  ) {
    updateFlowSolutionShape(
      shape_id: $shapeId
      board_id: $boardId
      title: $title
      metadata: $metadata
      connection: $connection
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const UPDATE_FLOW_SOLUTION_SHAPE_METADATA = gql`
  mutation UpdateFlowSolutionShapeMetadata(
    $title: String!
    $boardId: String!
    $shapeId: String!
    $metadata: String
    $connection: String!
  ) {
    updateFlowSolutionShape(
      title: $title
      shape_id: $shapeId
      board_id: $boardId
      metadata: $metadata
      connection: $connection
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const DELETE_FLOW_SOLUTION = gql`
  mutation DeleteFlowSolution($solutionId: String!, $projectId: String!, $connection: String!) {
    deleteFlowSolution(solution_id: $solutionId, project_id: $projectId, connection: $connection)
  }
`;

export const UPDATE_STATUS_FLOW_SOLUTION_BOARD_DATA = gql`
  mutation UpdateBoardState(
    $boardId: String!
    $projectId: String!
    $currentState: String!
    $connection: String!
    $statusChecklist: String
    $currentShapeExecuteId: String
  ) {
    updateBoardState(
      board_id: $boardId
      project_id: $projectId
      current_state: $currentState
      status_checklist: $statusChecklist
      connection: $connection
      current_shape_execute_id: $currentShapeExecuteId
    ) {
      currentState
    }
  }
`;

export const RESET_BOARD = gql`
  mutation ResetBoard($boardId: String!, $projectId: String!, $connection: String!) {
    resetBoard(board_id: $boardId, project_id: $projectId, connection: $connection) {
      boardId
      minScale
      maxScale
      canvasSize
      currentState
      shapes {
        shapeId
        title
        xCoordinate
        yCoordinate
        metadata
        width
        height
        type
      }
      connections {
        connectionId
        start
        end
        startPosition
        endPosition
        color
        type
      }
      threads {
        threadId
        boardId
        executeShapeId
        status
        createdAt
      }
      flowSolution {
        title
        solutionId
      }
      scheduler {
        schedulerId
        schedulerType
        schedulerLabel
        schedulerPattern
        isActive
      }
    }
  }
`;

export const UPDATE_FLOW_SOLUTION_CHECKLIST_SHAPE = gql`
  mutation UpdateFlowSolutionChecklistShape(
    $connection: String!
    $projectId: String!
    $boardId: String!
    $shapeId: String!
    $title: String!
    $metadata: String!
    $checklistItems: [ChecklistItem!]!
  ) {
    updateFlowSolutionChecklistShape(
      connection: $connection
      project_id: $projectId
      shape_id: $shapeId
      board_id: $boardId
      title: $title
      metadata: $metadata
      checklist_items: $checklistItems
    ) {
      shapeId
      title
      xCoordinate
      yCoordinate
      metadata
      width
      height
      type
    }
  }
`;

export const UPDATE_FLOW_SOLUTION_CHECKLIST_ITEMS_WITH_STATE = gql`
  mutation UpdateFlowSolutionChecklistItemsWithState(
    $connection: String!
    $projectId: String!
    $trackingId: String!
    $checklistItems: [ChecklistItemStatus!]!
  ) {
    updateFlowSolutionChecklistItemsWithState(
      connection: $connection
      project_id: $projectId
      tracking_id: $trackingId
      checklist_items: $checklistItems
    )
  }
`;

export const UPDATE_BOARD_SCHEDULER = gql`
  mutation UpdateBoardScheduler($connection: String!, $scheduler: BoardScheduler!) {
    updateBoardScheduler(connection: $connection, scheduler: $scheduler) {
      schedulerId
      isActive
      schedulerType
      schedulerLabel
      schedulerPattern
    }
  }
`;

export const UPDATE_NOTEBOOK_BY_BOARD_ID = gql`
  mutation UpdateNoteBookByBoardId($connection: String!, $boardId: String!, $content: String) {
    updateNotebook(connection: $connection, board_id: $boardId, content: $content) {
      content
    }
  }
`;

export const COPY_FLOW_SOLUTIONS = gql`
  mutation copyFlowSolutions($source: ProjectDetailsInput!, $target: ProjectDetailsInput!, $flowSolutions: [String!]!) {
    copyFlowSolutions(source: $source, target: $target, flow_solutions: $flowSolutions)
  }
`;
