import { memo } from 'react';

import ForgotCredentialsUI from 'modules/forgot-credentials';

const ForgotCredentials = () => {
  return (
    <>
      <ForgotCredentialsUI />
    </>
  );
};

export default memo(ForgotCredentials);
