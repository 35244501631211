import * as React from 'react';
import { getDisciplinesMiddleware } from '../middlewares/discipline';
import { Discipline } from '../interfaces/discipline';

export const useDisciplines = (categoryId: string) => {
  const [disciplines, setDisciplines] = React.useState<Discipline[]>([]);

  React.useEffect(() => {
    if (!categoryId) {
      return;
    }

    (async () => {
      const { isSuccess, data } = await getDisciplinesMiddleware(categoryId);
      if (isSuccess) {
        setDisciplines([...data]);
      }
    })();
  }, [categoryId]);

  return { disciplines, setDisciplines };
};
