import { gql } from '@apollo/client';

export const DELETE_PROJECT_SUBSCRIPTION = gql`
  subscription DeleteProjectSubscription {
    deleteProjectSubscription {
      id
      connection
    }
  }
`;
