import { Grid } from '@mui/material';
import { memo } from 'react';
import Breadcrumb from '../components/Breadcrumb';

const TabUi = ({ currentTab, onTabChange, breadcrumbs, tab, sidebar }) => {
  return (
    <Grid container spacing={2} style={{ padding: 20, flex: 1 }}>
      <Grid item xs={8} md={10} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ marginBottom: 25 }}>
          <Breadcrumb currentTab={currentTab} onClick={onTabChange} breadcrumbs={breadcrumbs} />
        </div>
        {tab}
      </Grid>
      <Grid item xs={4} md={2} style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ position: 'relative', flex: 1 }}>
          <div style={{ position: 'absolute', inset: 0, overflow: 'auto' }}>{sidebar}</div>
        </div>
      </Grid>
    </Grid>
  );
};

export default memo(TabUi);
