import { Grid } from '@mui/material';
import * as React from 'react';
import Button from '../components/Button';
import Dialog from '../components/Dialog';
import Select from '../components/Select';
import TextField from '../components/TextField';
import { createStatusMiddleware } from '../middlewares/status';
import { fireErrorsNotiStack, fireSuccessNotiStack } from '../services/notistack';
import { useFormStatus } from '../hooks/useFormStatus';
import { Legend } from '../interfaces/legend';

interface Props {
  callback: () => void;
  open: boolean;
  onClose: () => void;
  categoryId: string;
  legends: Legend[];
}
const AddStatusDialog: React.FC<Props> = ({ callback, categoryId, onClose, legends, ...otherProps }) => {
  const { form, setForm } = useFormStatus(otherProps.open, categoryId, legends?.[0]?.id);

  const handleChange = (key: string) => (event: any) => {
    setForm({
      ...form,
      [key]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const { isSuccess, message } = await createStatusMiddleware(form);
    if (!isSuccess) {
      fireErrorsNotiStack(message);
      return;
    }
    onClose();
    fireSuccessNotiStack(message);
    callback();
  };
  return (
    <Dialog
      title="Adding status"
      // @ts-ignore
      content={
        <Grid container style={{ marginTop: 10 }}>
          <Grid container style={{ marginBottom: 15 }}>
            <TextField label="Name" value={form.name} onChange={handleChange('name')} fullWidth isRequired />
          </Grid>
          <Grid container style={{ marginBottom: 15 }}>
            <Select
              value={form.legendId}
              label="Legend"
              onChange={handleChange('legendId')}
              options={legends}
              isRequired
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Set-Up"
                type="number"
                value={form.setUp}
                onChange={handleChange('setUp')}
                isRequired
                inputProps={{ min: 0, max: 100 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField label="Sign-Off" value={form.signOff} onChange={handleChange('signOff')} type="number" />
            </Grid>
          </Grid>
        </Grid>
      }
      actions={
        <Grid container direction="row" justifyContent="center">
          <Button style={{ marginLeft: 10, marginRight: 10 }} onClick={() => onClose()}>
            Cancel
          </Button>
          <Button variant="contained" style={{ marginLeft: 10, marginRight: 10 }} onClick={() => handleSubmit()}>
            Submit
          </Button>
        </Grid>
      }
      {...otherProps}
    />
  );
};

export default AddStatusDialog;
