import * as React from 'react';

const emptyForm = {
  name: '',
  legendId: '',
  categoryId: '',
  setUp: 0,
};
interface Form {
  name: string;
  legendId: string;
  categoryId: string;
  setUp: number;
  signOff?: number;
}

export const useFormStatus = (openDialog: boolean, categoryId: string, legendId?: string) => {
  const [form, setForm] = React.useState<Form>({ ...emptyForm });

  React.useEffect(() => {
    if (openDialog) {
      setForm({
        ...emptyForm,
        categoryId,
        legendId: legendId || '',
      });
    }
  }, [categoryId, legendId, openDialog]);

  return {
    form,
    setForm,
  };
};
