import { memo, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  InputAdornment,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Face, Email, Lock } from '@material-ui/icons';

import Input from 'modules/shared/components/Input';
import { authSelectors, authThunks, authActions } from 'modules/auth';
import { history } from 'configure/browserHistory';

import { useSearch } from 'modules/shared/hooks/url';
import { useStyles } from './styles';
import InfoItem from './InfoItem';
import { ITEMS } from './constants';

const RegisterUi = () => {
  const { get } = useSearch();

  const initEmail = get('email');
  const classes = useStyles();
  const formRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [user, setUser] = useState({
    username: '',
    email: initEmail,
    password: '',
  });

  const isLoading = useSelector(authSelectors.getIsLoading);
  const errors = useSelector(authSelectors.getAuthStateRegisterErrors);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(authActions.clearRegister());
    };
  }, [dispatch]);

  const register = useCallback(
    (e) => {
      e.preventDefault();

      dispatch(authThunks.register(user));
    },
    [user, dispatch],
  );

  const handleChange = useCallback(({ target }) => {
    const { id, value } = target;

    setUser((prev) => ({
      ...prev,
      [id]: value,
    }));
  }, []);

  const [animation, setAnimation] = useState('cardAnimation');
  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimation('');
    }, 50); // since modal opens on route change we need to add a little delay

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const goToHomeIfClickedOnOverlay = useCallback(({ target }) => {
    if (!formRef.current?.contains(target)) {
      history.replace('/home');
    }
  }, []);

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyPress={() => {}}
      className={classes.container}
      onClick={goToHomeIfClickedOnOverlay}
    >
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} md={8}>
          <div ref={formRef} className={classnames(classes.card, classes[animation])}>
            <h2 className={classes.cardTitle}>Register</h2>
            <Grid container justify="center" spacing={4}>
              <Grid item xs={10} sm={10} md={5}>
                {ITEMS.map((item) => (
                  <InfoItem key={item.title} title={item.title} description={item.description} image={item.img} />
                ))}
              </Grid>
              <Grid item xs={10} sm={10} md={5}>
                <form onSubmit={register}>
                  <Input
                    error={!!errors.length}
                    labelText="User Name.."
                    id="username"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: user.username,
                      required: true,
                      onChange: handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Face className={classes.inputIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Input
                    error={!!errors.length}
                    labelText="Email.."
                    id="email"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'email',
                      value: user.email,
                      required: true,
                      onChange: handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Email className={classes.inputIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Input
                    error={!!errors.length}
                    labelText="Password.."
                    id="password"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: 'password',
                      value: user.password,
                      required: true,
                      onChange: handleChange,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Lock className={classes.inputIcon} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormControlLabel
                    control={<Checkbox color="primary" name="agree" required className={classes.checkbox} />}
                    label={
                      <span className={classes.checkboxInfo}>
                        I agree to the <span className={classes.checkboxLink}>terms and conditions</span>
                      </span>
                    }
                  />
                  <div className={classes.registerButtonContainer}>
                    <Button variant="contained" type="submit" className={classes.registerButton}>
                      {isLoading ? <CircularProgress size={20} /> : 'Get started'}
                    </Button>
                  </div>
                  <Collapse in={!!errors.length}>
                    <Alert severity="error" className={classes.errorInfo}>
                      <ul className={classes.list}>
                        {errors.map((err) => (
                          <li key={err}>{err}</li>
                        ))}
                      </ul>
                    </Alert>
                  </Collapse>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(RegisterUi);
