import { useSubscription } from '@apollo/client';
import { Button } from '@mui/material';
import { getConnections } from 'modules/auth/selectors';
import { ConnectionsContext } from 'modules/shared/contexts/ConnectionContext/context';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { CONNECTION_DEACTIVATION_SUBSCRIPTION } from './gql';

export const useDeactivateConnectionListener = (skip: boolean) => {
  const flags = useContext(ConnectionsContext);
  const { enqueueSnackbar } = useSnackbar();

  const connections = useSelector(getConnections);

  useSubscription(CONNECTION_DEACTIVATION_SUBSCRIPTION, {
    skip,
    onSubscriptionData: ({
      subscriptionData: {
        data: { connectionDeactivationSubscription },
      },
    }) => {
      if (
        flags.deactivated !== connectionDeactivationSubscription.name &&
        connections.includes(connectionDeactivationSubscription.name)
      ) {
        enqueueSnackbar('One of your connections was deactivated. Please refresh app', {
          ...BASE_ERROR_NOTIFICATION_OPTIONS,
          persist: true,
          action: () => {
            return (
              <Button
                style={{ color: 'white' }}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Refresh
              </Button>
            );
          },
        });
      }

      flags.deactivated = '';
    },
  });
};
