import { memo } from 'react';

import ChangePasswordUI from 'modules/change-password';

const ChangePassword = () => {
  return (
    <>
      <ChangePasswordUI />
    </>
  );
};

export default memo(ChangePassword);
