import { useSubscription } from '@apollo/client';
import { MyProject } from '../projects';

import { DELETE_PROJECT_SUBSCRIPTION } from './gql';

export const useDeleteProjectSubscription = (skip: boolean) => {
  useSubscription(DELETE_PROJECT_SUBSCRIPTION, {
    skip,
    onSubscriptionData: ({
      client,
      subscriptionData: {
        data: { deleteProjectSubscription },
      },
    }) => {
      client.cache.modify({
        fields: {
          myProjects(prev) {
            return prev.filter(
              (p: MyProject) =>
                !(
                  p.project.projectId === deleteProjectSubscription.id &&
                  p.projectConnection === deleteProjectSubscription.connection
                ),
            );
          },
        },
      });
    },
  });
};
