import { memo } from 'react';

import RegisterUi from 'modules/register-ui';

const Register = () => {
  return (
    <>
      <RegisterUi />
    </>
  );
};

export default memo(Register);
