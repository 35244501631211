/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Grid, TextareaAutosize, Typography } from '@mui/material';
import * as React from 'react';
import Button from '../components/Button';
import { saveNotesMiddleware } from '../middlewares/note';
import { fireErrorNotiStack, fireSuccessNotiStack } from '../services/notistack';
import { useNotes } from '../hooks/useNotes';

interface Props {
  categoryId: string;
}
const Notes: React.FC<Props> = ({ categoryId }) => {
  const { note, setNote } = useNotes(categoryId);

  const handleSubmit = React.useCallback(async () => {
    const { isSuccess, message, data } = await saveNotesMiddleware(
      { categoryId, content: note?.content || '' },
      note?.id,
    );
    if (isSuccess) {
      fireSuccessNotiStack(message);
      data &&
        setNote({
          ...note,
          categoryId,
          id: data.id,
        });
      return;
    }
    fireErrorNotiStack(message);
  }, [categoryId, note, setNote]);

  const onChange = React.useCallback(
    (event: any) => {
      setNote({
        ...note,
        content: event.target.value,
        categoryId,
      });
    },
    [categoryId, note, setNote],
  );
  return (
    <Grid container>
      <Grid container>
        <Typography variant="h6">Notes</Typography>
      </Grid>
      <Grid container>
        <TextareaAutosize
          minRows={15}
          maxRows={15}
          style={{ resize: 'none', width: '100%' }}
          value={note?.content || ''}
          onChange={onChange}
          onReset={undefined}
          onResetCapture={undefined}
          // @ts-ignore
          onResize={undefined}
          onResizeCapture={undefined}
        />
      </Grid>
      <Grid container justifyContent="flex-end" style={{ marginTop: 5 }}>
        <Button onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(Notes);
