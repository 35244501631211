import * as React from 'react';

interface Props {
  color: string;
  style?: React.CSSProperties;
}
const Color: React.FC<Props> = ({ color, style }) => {
  return (
    <div
      style={{
        backgroundColor: color,
        width: '100%',
        height: '100%',
        ...style,
      }}
    />
  );
};

export default React.memo(Color);
