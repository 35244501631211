import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { FileManagerComponent } from '@syncfusion/ej2-react-filemanager';
import { confirm } from 'devextreme/ui/dialog';
import { useSnackbar } from 'notistack';
import { Button } from 'devextreme-react/data-grid';
import Swiper from 'modules/shared/components/Swiper';
import Table from 'modules/shared/components/Table';
import { getApiBaseUrl, noop } from 'modules/shared/utils';

import { BASE_INFO_NOTIFICATION_OPTIONS } from 'project-constants';
import { useHdfs } from 'modules/shared/hooks/api/hdfs';
import { useToggle } from 'modules/shared/hooks/base';

import FileManager from 'modules/shared/components/FileManager';
import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { useLoadSyncPathes } from 'modules/data-stores-custom/hooks/useLoadSyncPathes';
import { useSyncPathActions } from 'modules/data-stores-custom/hooks/useSyncPathActions';
import SyncPathSchedulerModalUi from 'modules/data-stores-custom/sync-path-scheduler-modal-ui';
import ProjectSelectModal from 'modules/shared/components/ProjectSelectModal';
import { useToggleWithValue } from 'modules/shared/hooks/base/useToggleWithValue';
import UploadModal from '../UploadModal';
import CustomConnectionsUploadFolderExplorer from './CustomConnectionsUploadFolderExplorer';
import { useStyles } from './styles';
import { useCopyHdfsFiles } from '../hooks';

const ID = 'hadoop-hdfs';
const hostUrl = getApiBaseUrl();

const UPLOAD_TO_CUSTOM_CONNECTION = 'Exports';
const COPY_TO_ANOTHER_PROJECT = 'Copy';

const COPY_AVAILABLE_PATH = ['pcube_configs', 'pcube_mappings', 'pcube_validations'];
const isAbleToCopy = (rootPath) => COPY_AVAILABLE_PATH.includes(rootPath);

const DataStoresUI = ({ readOnly = false, renderButtons }) => {
  const classes = useStyles();

  const { archive } = useHdfs();
  const { enqueueSnackbar } = useSnackbar();

  const [category, setCategory] = useState('');
  const [template, setTemplate] = useState('');

  const { projectId, connection } = useProjectInfoFromSearch();

  const { copy } = useCopyHdfsFiles();

  const onClickToolbarUpload = () => {
    setCategory('');
    setTemplate('');
  };

  const onClickContextUpload = (directoryPath: string) => {
    const [categoryPath, templatePath] = directoryPath.replace(/\/?\\\/?/g, '').split('/');

    setCategory(categoryPath || '');
    setTemplate(templatePath || '');
  };

  const [isUploadModalOpen, { activate: openUploadModal, deactivate: closeUploadModal }] = useToggle();
  const [isUploadToCustomOpen, { activate: openUploadToCustomModal, deactivate: closeUploadToCustomModal }] =
    useToggle();
  const fileManager = useRef<FileManagerComponent | null>(null);

  const { pathes, loading } = useLoadSyncPathes({ connection, dataStoreConnection: '', projectId, type: 'export' });
  const { remove, create, creating, updateScheduler, updatingScheduler } = useSyncPathActions({
    connection,
    projectId,
    type: 'export',
  });
  const [editingPathId, setEditingPathId] = useState('');

  const onDeleteRow = useCallback(
    (newRow) => {
      remove(newRow.data.pathId);
    },
    [remove],
  );

  const p = useMemo(() => JSON.parse(JSON.stringify(pathes)), [pathes]);
  const [isSchedulerModalOpen, { deactivate: close, activate: open }] = useToggle(false);

  const initialValues = useMemo(() => {
    if (editingPathId) {
      const path = pathes.find((cur) => cur.pathId === editingPathId);

      return {
        name: path?.name,
        dataStoreConnection: path?.dataStoreConnectionId,
        scheduler: path?.scheduler.type || '',
        schedulerValue: path?.scheduler.pattern.split(' ') || null,
        schedulerLabel: path?.scheduler.label || '',
      };
    }

    return {
      name: '',
      scheduler: '',
      schedulerValue: '',
      schedulerLabel: '',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingPathId, pathes, isSchedulerModalOpen]);

  const closeModal = useCallback(() => {
    setEditingPathId('');
    close();
  }, [close]);

  const {
    value: selectedFiles,
    isOpen,
    activate: openProjectSelectModal,
    deactivate: closeProjectSelectModal,
  } = useToggleWithValue<any[]>([]);

  const fileManagerComponent = projectId ? (
    <>
      <FileManager
        id={ID}
        path="/hdfs-access"
        ref={fileManager}
        key={`${connection}-${projectId}`}
        beforeSendBody={{
          connection,
          projectId,
        }}
        customToolbarItems={
          readOnly
            ? []
            : [
                { name: UPLOAD_TO_CUSTOM_CONNECTION, icon: 'e-upload-1', disabled: true },
                { name: COPY_TO_ANOTHER_PROJECT, icon: 'e-copy-1', disabled: true },
              ]
        }
        onSuccess={() => {
          fileManager.current?.disableToolbarItems([UPLOAD_TO_CUSTOM_CONNECTION, COPY_TO_ANOTHER_PROJECT]);
        }}
        onFileSelect={() => {
          fileManager.current?.disableToolbarItems([UPLOAD_TO_CUSTOM_CONNECTION, COPY_TO_ANOTHER_PROJECT]);

          if (fileManager.current?.selectedItems?.length) {
            const enabledItems = [UPLOAD_TO_CUSTOM_CONNECTION];

            if (isAbleToCopy(fileManager.current!.pathNames?.[1])) {
              enabledItems.push(COPY_TO_ANOTHER_PROJECT);
            }
            fileManager.current.enableToolbarItems(enabledItems);
          } else {
            fileManager.current?.disableToolbarItems([UPLOAD_TO_CUSTOM_CONNECTION, COPY_TO_ANOTHER_PROJECT]);
          }
        }}
        onToolbarClick={(args) => {
          if (args.item.properties.id === `${ID}_tb_upload`) {
            args.cancel = true;
            onClickToolbarUpload();
            openUploadModal();
          }
          if (args.item.properties.text === UPLOAD_TO_CUSTOM_CONNECTION) {
            args.cancel = true;

            openUploadToCustomModal();
          }
          if (args.item.properties.text === COPY_TO_ANOTHER_PROJECT) {
            args.cancel = true;

            openProjectSelectModal(args.fileDetails);
          }
        }}
        onMenuClick={(args) => {
          if (args.item.properties.id === `${ID}_cm_upload`) {
            args.cancel = true;
            onClickContextUpload(args.fileDetails[0].filterPath);
            openUploadModal();
          }
          if (args.item.properties.text === COPY_TO_ANOTHER_PROJECT) {
            args.cancel = true;
            openProjectSelectModal(args.fileDetails);
          }
        }}
        onBeforeDownload={(args) => {
          args.cancel = true;

          archive({
            connection,
            projectId,
            path: args.data.path,
            files: args.data.data.map((it) => ({
              isFile: it.isFile,
              name: it.name,
            })),
          }).then((response) => {
            if (response.data.archive) {
              enqueueSnackbar(
                "Your archive is being prepared. You'll receive an notification once it ready to be downloaded",
                BASE_INFO_NOTIFICATION_OPTIONS,
              );
            } else {
              const a = document.createElement('a');
              a.href = response.data.link;
              a.download = response.data.filename;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            }
          });
        }}
        allowFolderCreate={!readOnly}
        allowDownload
        allowUpload={!readOnly}
        allowRename={!readOnly}
        allowDelete={!readOnly}
        allowFileOpen
        customUploadProps={{
          connection,
          projectId,
          settings: {
            saveUrl: `${hostUrl}/hdfs-access/upload`,
            removeUrl: `${hostUrl}/hdfs-access/delete`,
            chunkSize: 1024 * 1024 * 3, // 3 MB
            retryCount: 0,
          },
        }}
        customFileContextMenuActions={readOnly ? [] : [COPY_TO_ANOTHER_PROJECT]}
        customFolderContextMenuActions={readOnly ? [] : [COPY_TO_ANOTHER_PROJECT]}
        onMenuOpen={(args) => {
          const copyAvailable = isAbleToCopy(fileManager.current!.pathNames?.[1]);

          args.items = args.items.reduce((acc, item) => {
            if (item.properties.text === COPY_TO_ANOTHER_PROJECT) {
              item.iconCss = 'e-icons e-fe-copy';

              if (copyAvailable) {
                return acc.concat(item);
              }

              return acc;
            }
            return acc.concat(item);
          }, []);
        }}
      />
      {!readOnly && (
        <>
          <ProjectSelectModal
            isOpen={isOpen}
            onClose={closeProjectSelectModal}
            onSubmit={async (next) => {
              const confirmed = await confirm(
                'Existing files will be overridden. Do you want to proceed?',
                'Confirm copy data',
              );

              if (confirmed) {
                await copy(
                  { connection, project: projectId },
                  next,
                  selectedFiles.map((f) => {
                    const path = f.filterPath.replace(/\/?\\\/?/g, '/');

                    return {
                      from: `${path}${f.name}`,
                      to: `${path}${f.name}`,
                    };
                  }),
                );

                enqueueSnackbar(
                  'Copy process is in progress. We will notify you once it completes',
                  BASE_INFO_NOTIFICATION_OPTIONS,
                );
              }
            }}
          />
          <CustomConnectionsUploadFolderExplorer
            connection={connection}
            projectId={projectId}
            isOpen={isUploadToCustomOpen}
            onClose={() => {
              if (!creating) {
                closeUploadToCustomModal();
              }
            }}
            executing={creating}
            onCreate={(data) => {
              const basePath = fileManager.current!.pathNames.slice(1).join('/');
              create(
                () => {
                  fileManager.current!.selectedItems = [];
                  closeUploadToCustomModal();
                },
                data.selectedConnection,
                data.name,
                { filter: '', unzip: false, loadDeltaLake: true },
                fileManager.current!.itemData.map((f) => ({
                  // @ts-ignore
                  isFile: f.isFile,
                  // @ts-ignore
                  hdfsPath: `/${basePath}/${f.name}`.replace('//', '/'),
                  externalPath: data.path,
                })),
                data.scheduler,
              );
            }}
          />
          <UploadModal
            connection={connection}
            projectId={projectId}
            category={category}
            template={template}
            isOpen={isUploadModalOpen}
            onClose={closeUploadModal}
            refresh={() => {
              fileManager.current?.refreshFiles();
            }}
          />
        </>
      )}
    </>
  ) : null;

  const table = (
    <div className={classes.mainContainer}>
      <div className={classes.wrapper}>
        <Table data={p} loading={loading} onUpdateRow={noop} onDeleteRow={onDeleteRow} height="100%">
          <Table.Column dataField="dataStoreConnection.name" caption="Connection" minWidth={120} />
          <Table.Column dataField="name" caption="Name" minWidth={120} />
          <Table.Column dataField="path" caption="Path" minWidth={120} />
          <Table.Column dataField="hdfsLocation" caption="Storage Path" minWidth={120} />
          <Table.Column dataField="scheduler.label" caption="Scheduler" alignment="center" />
          {!readOnly && (
            <Table.Column key="buttons" type="buttons" width={110}>
              <Button
                name="edit"
                onClick={(args) => {
                  setEditingPathId(args.row.data.pathId);
                  open();
                }}
              />
              <Button name="delete" />
            </Table.Column>
          )}
        </Table>
        <SyncPathSchedulerModalUi
          isOpen={isSchedulerModalOpen}
          close={closeModal}
          initialValues={initialValues}
          executing={creating || updatingScheduler}
          onSubmit={(name, meta, scheduler) => {
            return updateScheduler(
              closeModal,
              meta.dataStoreConnection,
              editingPathId,
              name,
              { filter: '', unzip: false, loadDeltaLake: true },
              scheduler,
            );
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <Swiper
        renderButtons={renderButtons}
        items={[
          {
            title: 'File Manager',
            component: fileManagerComponent,
          },
          {
            title: 'Exports',
            component: table,
          },
        ]}
      />
    </>
  );
};

export default memo(DataStoresUI);
