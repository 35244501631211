import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Board, Connection, Shape } from 'modules/shared/components/DraggableLayout';
import { useSnackbar } from 'notistack';
import { BASE_ERROR_NOTIFICATION_OPTIONS } from 'project-constants';
import { useSelector } from 'react-redux';
import { authSelectors } from 'modules/auth';
import {
  LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID,
  RESET_BOARD,
  UPDATE_STATE_FLOW_SOLUTION_SUBSCRIPTION,
  UPDATE_STATUS_FLOW_SOLUTION_BOARD_DATA,
} from '../gql';
import { FlowSolutionBoard, FlowSolutionShape, FlowSolutionConnector, UnparsedFlowSolutionBoard } from '../types';
import { updateFlowSolutionBoard } from '../utils/flowSolutionBoardsSubscription';

const BASE_CONNECTION = {
  curveness: 0.7,
  headSize: 4,
  tailSize: 4,
};

const parseCard = (cards: FlowSolutionShape[]): Shape[] => {
  return cards.map((card) => {
    return {
      id: card.shapeId,
      xCoordinate: card.xCoordinate,
      yCoordinate: card.yCoordinate,
      metadata: JSON.parse(card.metadata),
      title: card.title,
      width: card.width,
      height: card.height,
      type: card.type,
    };
  });
};

const parseConnector = (connections: FlowSolutionConnector[]): Connection[] => {
  return connections.map((connection) => {
    return {
      connectionId: connection.connectionId,
      start: connection.start,
      end: connection.end,
      startPosition: connection.startPosition,
      endPosition: connection.endPosition,
      color: connection.color,
      type: connection.type,
      ...BASE_CONNECTION,
      uniqKey: Math.random(),
    };
  });
};

const parseBoard = (board: FlowSolutionBoard): Board | undefined => {
  if (board) {
    return {
      boardId: board.boardId,
      maxScale: board.maxScale,
      minScale: board.minScale,
      canvasSize: board.canvasSize,
      shapes: parseCard(board.shapes),
      connections: parseConnector(board.connections),
      currentState: board.currentState,
      threads: board.threads ?? [],
      flowSolution: board.flowSolution ?? undefined,
      scheduler: board.scheduler,
    };
  }

  return undefined;
};

export const useFlowSolutionBoardBySolutionId = (
  solutionId: string,
  projectId: string,
  connection: string,
  skip: boolean,
  onError: () => void,
) => {
  const userId = useSelector(authSelectors.getUserId);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const { data, loading, refetch, error, subscribeToMore } = useQuery(LOAD_FLOW_SOLUTION_BOARD_BY_SOLUTION_ID, {
    variables: {
      solutionId,
      connection,
    },
    skip: !connection || !solutionId || skip,
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'network-only',
  });

  const board = useMemo(
    () => parseBoard(data?.flowSolutionBoardBySolutionId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [solutionId, data?.flowSolutionBoardBySolutionId],
  );

  useLayoutEffect(() => {
    const unsubscribe = subscribeToMore({
      document: UPDATE_STATE_FLOW_SOLUTION_SUBSCRIPTION,
      variables: {
        userId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        const updatedFlow = subscriptionData.data.changeFlowSolutionStateSubscription as UnparsedFlowSolutionBoard;

        if (board?.boardId !== updatedFlow.boardId) {
          return prev;
        }

        return {
          ...prev,
          flowSolutionBoardBySolutionId: updateFlowSolutionBoard(prev.flowSolutionBoardBySolutionId, updatedFlow, [
            'currentState',
            'threads',
          ]),
        };
      },
    });

    return () => {
      unsubscribe();
    };
  }, [subscribeToMore, userId, board]);

  const [updateColumn] = useMutation(UPDATE_STATUS_FLOW_SOLUTION_BOARD_DATA, {
    update(cache, { data: { updateBoardState } }) {
      cache.modify({
        fields: {
          flowSolutionBoardBySolutionId(currentBoard) {
            if (currentBoard.flowSolution?.solutionId === solutionId) {
              return {
                ...currentBoard,
                currentState: updateBoardState.currentState,
              };
            }
            return currentBoard;
          },
        },
      });
    },
  });

  const updateState = useCallback(
    (column) => {
      setIsLoadingUpdate(true);
      return updateColumn({
        variables: {
          connection,
          projectId,
          ...column,
        },
      })
        .catch((_error) => {
          enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
          setIsLoadingUpdate(false);
        })
        .finally(() => {
          setIsLoadingUpdate(false);
        });
    },
    [updateColumn, enqueueSnackbar, connection, projectId],
  );

  const [resetBoardMutation] = useMutation(RESET_BOARD, {
    update(cache, { data: { resetBoard } }) {
      cache.modify({
        fields: {
          flowSolutionBoardBySolutionId(currentBoard) {
            if (currentBoard.flowSolution?.solutionId === solutionId) {
              return resetBoard;
            }
            return currentBoard;
          },
        },
      });
    },
  });

  const resetBoard = useCallback(() => {
    setIsLoadingUpdate(true);
    return resetBoardMutation({
      variables: {
        connection,
        projectId,
        boardId: board?.boardId,
      },
    })
      .catch((_error) => {
        enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        setIsLoadingUpdate(false);
      })
      .finally(() => {
        setIsLoadingUpdate(false);
      });
  }, [resetBoardMutation, connection, projectId, board?.boardId, enqueueSnackbar]);

  const refetchBoard = useCallback(() => {
    setIsLoadingUpdate(true);
    return refetch()
      .catch((_error) => {
        enqueueSnackbar(_error.message, BASE_ERROR_NOTIFICATION_OPTIONS);
        setIsLoadingUpdate(false);
      })
      .finally(() => {
        setIsLoadingUpdate(false);
      });
  }, [refetch, enqueueSnackbar]);

  useEffect(() => {
    if (error && !loading) {
      onError();
    }
  }, [error, loading, onError]);

  return {
    board,
    loading,
    updateState,
    isLoadingUpdate,
    refetch: refetchBoard,
    resetBoard,
  };
};
