import { Grid, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import * as React from 'react';
import { Category } from '../interfaces/category';

interface Props {
  categories: Category[];
  selectedIndex: number;
  setSelectedIndex: (index: number) => void;
  style?: React.CSSProperties;
}
const Categories: React.FC<Props> = ({ categories, selectedIndex, setSelectedIndex, style }) => {
  const handleListItemClick = React.useCallback(
    (event, index: number) => {
      setSelectedIndex(index);
    },
    [setSelectedIndex],
  );
  return (
    <Grid container justifyContent="center" style={style}>
      <Grid container style={{ marginBottom: 15 }}>
        <Typography variant="h6">My Data</Typography>
      </Grid>

      <List style={{ padding: 0 }}>
        {categories.map((category, index) => {
          return (
            <ListItem
              key={category.id}
              style={{
                padding: 0,
              }}
            >
              <ListItemButton
                style={{ borderRadius: 5, textTransform: 'capitalize' }}
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
              >
                <ListItemText primary={category.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
};

export default React.memo(Categories);
