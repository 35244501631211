import { memo } from 'react';
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableCell,
  CircularProgress,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { AmountItems, useTablePaginationWithSearch } from 'modules/shared/hooks/base';
import { ALL_VALUE } from 'modules/shared/constants';

import { useStyles } from './styles';
import { useUsers } from '../hooks';

const UsersTableUi = () => {
  const classes = useStyles();

  const { amount, onAmountChange, search, onSearchChange, page, onPageChange } = useTablePaginationWithSearch();

  const { users, totalCount, loading } = useUsers({
    search,
    limit: amount,
    offset: (page - 1) * amount,
  });
  const showPagination = Boolean(amount && amount < totalCount);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item sm={12} md={7} lg={8}>
          <div className={classes.amountContainer}>
            Show
            <Select className={classes.amountSelect} value={amount || ALL_VALUE} onChange={onAmountChange}>
              {AmountItems.map((item) => {
                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
            entries
          </div>
        </Grid>
        <Grid item sm={12} md={5} lg={4}>
          <div className={classes.searchContainer}>
            <span className={classes.searchLabel}>Search:</span>
            <TextField
              value={search}
              size="small"
              variant="outlined"
              className={classes.search}
              onChange={onSearchChange}
              fullWidth
            />
          </div>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell className={classes.colSize20}>Username</TableCell>
              <TableCell className={classes.colSize20}>Email</TableCell>
              <TableCell className={classes.colSize10}>Status</TableCell>
              <TableCell className={classes.colSize30}>Last Activity</TableCell>
              <TableCell className={classes.colSize20}>Joined</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Boolean(!loading && users.length) &&
              users.map((user) => (
                <TableRow key={user.userId}>
                  <TableCell>{user.userName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.status}</TableCell>
                  <TableCell className={classes.noWrap}>{user.lastActive}</TableCell>
                  <TableCell className={classes.noWrap}>{user.joinedDate}</TableCell>
                </TableRow>
              ))}
            {loading && (
              <TableRow>
                <TableCell colSpan={9}>
                  <div className={classes.loading}>
                    <CircularProgress size={20} /> <span className={classes.loadingText}>Loading data</span>
                  </div>
                </TableCell>
              </TableRow>
            )}
            {!loading && !users.length && (
              <TableRow>
                <TableCell colSpan={9}>
                  <div className={classes.noData}>
                    <span>No data to display</span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {showPagination && (
        <div className={classes.tableConfig}>
          <div className={classes.paginationContainer}>
            <Pagination count={Math.ceil(totalCount / amount)} size="medium" page={page} onChange={onPageChange} />
          </div>
        </div>
      )}
    </>
  );
};

export default memo(UsersTableUi);
