import { memo } from 'react';

import PageSelector from 'modules/shared/components/page-selector-ui';
import { DataSpacesPages } from './constants';
import { useStyles } from './styles';

const DataSpaces = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <PageSelector Pages={DataSpacesPages} />
    </div>
  );
};

export default memo(DataSpaces);
