import axios from 'axios';
import { BaseStatus, Status, StatusParams, StatusUpdate } from '../interfaces/status';
import StatusList from '../mocks/status.json';
import Legends from '../mocks/legends.json';
import Colors from '../mocks/colors.json';

export const getStatusesMiddleware = async (
  categoryId: string,
  params?: StatusParams,
): Promise<{
  isSuccess: boolean;
  data: Status[];
}> => {
  try {
    const { status } = StatusList;
    const { legends } = Legends;
    const { colors } = Colors;
    let statusListByCategoryId = status.filter((el) => el.category_id === categoryId);
    if (params?.legendIds?.length) {
      statusListByCategoryId = statusListByCategoryId.filter((el) => params?.legendIds?.includes(el.legend_id));
    }
    return {
      isSuccess: true,
      data: statusListByCategoryId.map((el) => {
        const legend = legends.find((lg) => lg.id === el.legend_id) as any;
        if (legend) {
          legend.color = colors.find((color) => color.id === legend.color_id);
        }
        return {
          ...el,
          setUp: el.set_up,
          signOff: el.sign_off,
          legendId: el.legend_id,
          legend,
          categoryId,
        } as Status;
      }),
    };
  } catch (error) {
    return { isSuccess: false, data: [] };
  }
};

export const updateStatusMiddleware = async (
  id: string,
  body: StatusUpdate | null,
): Promise<{
  isSuccess: boolean;
  data: Status | null;
  message: string;
}> => {
  try {
    const { data } = await axios.put(`statuses/${id}`, body);
    return { isSuccess: true, data: data.data, message: data.message };
  } catch (error) {
    return {
      isSuccess: false,
      data: null,
      message: 'Something wrong when update status!',
    };
  }
};

export const createStatusMiddleware = async (
  body: BaseStatus,
): Promise<{
  isSuccess: boolean;
  data: Status | null;
  message: any;
}> => {
  try {
    body.setUp = Number(body.setUp);
    if (body.signOff != null) {
      body.signOff = Number(body.setUp);
    }
    const { data } = await axios.post(`statuses`, body);
    return { isSuccess: true, data: data.data, message: data.message };
  } catch (error: any) {
    return {
      isSuccess: false,
      data: null,
      message: error.response?.data?.message || 'Something wrong when update status!',
    };
  }
};
