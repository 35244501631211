import * as Pages from 'pages';

import { authTools } from 'modules/auth';

export const ENTRY_APP_PATH = '/projects';

export enum AuthRoutesPath {
  Dashboard = '/dashboard',
  NewDashboard = '/new-dashboard',
  NeoBoard = '/neo-board',
  Users = '/users',
  Applications = '/applications',
  DigitalThreads = '/digital-threads',
  FlowSolutionsEdit = '/flow-solutions/edit',
  FlowSolutions = '/flow-solutions',
  Integrations = '/integrations',
  FlowControl = '/flow-control',
  ExecutionLog = '/execution-log',
  DataValidations = '/data-validations',
  TimeTravel = '/time-travel',
  MasterData = '/master-data',
  Projects = '/projects',
  DataSpaces = '/data-spaces',
  DataStores = '/data-stores',
  ConnectionManager = '/connection-manager',
}

export const AUTH_ROUTES = [
  {
    category: 'Main',
    subCategory: 'Workspace',
    exact: true,
    path: AuthRoutesPath.NewDashboard,
    component: authTools.userIsAuthenticated(
      authTools.adminOrAtleastOneProjectWithRedirect(Pages.NewDashboard, '/projects'),
    ),
  },
  // {
  //   category: 'Main',
  //   subCategory: 'Stakeholders',
  //   exact: true,
  //   path: '/stakeholders',
  //   component: authTools.userIsAuthenticated(authTools.adminOnly(Pages.Stakeholders)),
  // },
  // {
  //   category: 'Main',
  //   subCategory: 'Assets',
  //   exact: true,
  //   path: '/assets',
  //   component: authTools.userIsAuthenticated(Pages.Assets),
  // },
  // {
  //   category: 'Main',
  //   subCategory: 'Neo-Spaces',
  //   exact: true,
  //   path: '/neo-spaces',
  //   component: authTools.userIsAuthenticated(Pages.NeoSpaces),
  // },
  {
    category: 'Main',
    subCategory: 'Neo Board',
    exact: true,
    path: AuthRoutesPath.NeoBoard,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.NeoBoard)),
  },
  {
    category: 'Main',
    subCategory: 'Users',
    exact: true,
    path: AuthRoutesPath.Users,
    component: authTools.userIsAuthenticated(Pages.Users),
  },
  {
    category: 'Main',
    subCategory: 'Applications',
    exact: true,
    path: AuthRoutesPath.Applications,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.Applications)),
  },
  {
    category: 'Main',
    subCategory: 'Digital Threads',
    exact: true,
    path: AuthRoutesPath.DigitalThreads,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.DigitalThreads)),
  },
  {
    category: 'Main',
    subCategory: 'Flow-Solutions',
    exact: true,
    path: AuthRoutesPath.FlowSolutionsEdit,
    component: authTools.userIsAuthenticated(authTools.adminOrSuperUserOnly(Pages.EditFlowSolutionBoard)),
  },
  {
    category: 'Main',
    subCategory: 'Flow-Solutions',
    exact: true,
    path: AuthRoutesPath.FlowSolutions,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.FlowSolutions)),
  },
  {
    category: 'Main',
    subCategory: 'Integrations',
    exact: true,
    path: AuthRoutesPath.Integrations,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.Integrations)),
  },
  {
    category: 'Main',
    subCategory: 'Flow Control',
    exact: true,
    path: AuthRoutesPath.FlowControl,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.FlowControl)),
  },
  {
    category: 'Main',
    subCategory: 'Execution Log',
    exact: true,
    path: AuthRoutesPath.ExecutionLog,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.ExecutionLog)),
  },
  {
    category: 'Main',
    subCategory: 'Data Validations',
    exact: true,
    path: AuthRoutesPath.DataValidations,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.DataValidations)),
  },
  {
    category: 'Main',
    subCategory: 'Time Travel',
    exact: true,
    path: AuthRoutesPath.TimeTravel,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.TimeTravel)),
  },
  {
    category: 'Main',
    subCategory: 'Master Data',
    exact: true,
    path: AuthRoutesPath.MasterData,
    component: authTools.userIsAuthenticated(authTools.adminOrSuperUserOnly(Pages.ConfigTables)),
  },
  // {
  //   category: 'Main',
  //   subCategory: 'Neo Space Templates',
  //   exact: true,
  //   path: '/neo-space-templates',
  //   component: authTools.userIsAuthenticated(authTools.
  //   adminOrAtleastOneProjectWithRedirect(Pages.NeoSpaceTemplates)),
  // },
  {
    category: 'Main',
    subCategory: 'Capital Projects',
    exact: true,
    path: AuthRoutesPath.Projects,
    component: authTools.userIsAuthenticated(Pages.CapitalProjects),
  },

  {
    category: 'Main',
    subCategory: 'Capital Projects',
    exact: false,
    path: `${AuthRoutesPath.Projects}/:pageId?`,
    component: authTools.userIsAuthenticated(Pages.Project),
  },
  {
    category: 'Main',
    subCategory: 'Data Spaces',
    exact: true,
    path: AuthRoutesPath.DataSpaces,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.DataSpaces)),
  },

  {
    category: 'Main',
    subCategory: 'Data Stores',
    exact: true,
    path: AuthRoutesPath.DataStores,
    component: authTools.userIsAuthenticated(authTools.adminOrAtleastOneProjectWithRedirect(Pages.DataStores)),
  },

  {
    exact: true,
    path: AuthRoutesPath.ConnectionManager,
    component: authTools.userIsAuthenticated(authTools.globalAdminOnly(Pages.ConnectionManager)),
  },
];

export const APP_ROUTES = [
  ...AUTH_ROUTES,
  {
    exact: true,
    path: '/register',
    component: authTools.userIsNotAuthenticated(Pages.Register),
  },
  {
    exact: true,
    path: '/login',
    component: authTools.userIsNotAuthenticated(Pages.Login),
  },
  {
    exact: true,
    path: '/forgot-credentials',
    component: authTools.userIsNotAuthenticated(Pages.ForgotCredentials),
  },
  {
    exact: false,
    path: '/change-password/:token',
    component: authTools.userIsNotAuthenticated(Pages.ChangePassword),
  },
  {
    exact: true,
    path: '/sso',
    component: authTools.userIsNotAuthenticated(Pages.SSO),
  },
  {
    exact: true,
    path: ENTRY_APP_PATH,
    component: authTools.userIsNotAuthenticated(Pages.Home),
  },
];
