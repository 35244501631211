import { LegendType } from '../enums/legend';
import { Legend } from '../interfaces/legend';
import Legends from '../mocks/legends.json';
import Colors from '../mocks/colors.json';

export const getLegendsMiddleware = async (
  categoryId: string,
  type?: LegendType,
): Promise<{
  isSuccess: boolean;
  data: Legend[];
}> => {
  try {
    const { legends } = Legends;
    const legendsByCategoryId = legends.filter((el) => el.category_id === categoryId);
    const legendsByType = legendsByCategoryId.filter((el) => el.type === (type || LegendType.GUIDE));
    const { colors } = Colors;
    return {
      isSuccess: true,
      data: legendsByType.map((el) => {
        return {
          ...el,
          color: colors.find((color) => color.id === el.color_id),
        };
      }) as any,
    };
  } catch (error) {
    return { isSuccess: false, data: [] };
  }
};
