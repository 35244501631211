import { useProjectInfoFromSearch } from 'modules/shared/hooks/helpers';
import { memo, useMemo } from 'react';
import { useToggle } from 'modules/shared/hooks/base';
import { HistoryHandlersContext, HistoryContext } from './context';
import { useLoadHistoryItems } from '../hooks/useLoadHistoryItems';
import { useLoadHistoryItemConfig } from '../hooks/useLoadHistoryItemConfig';

const HistoryProvider = ({ children, templateId }) => {
  const { connection, projectId } = useProjectInfoFromSearch();

  const { loading: itemsLoading, historyItems } = useLoadHistoryItems({
    connection,
    projectId,
    dataTemplate: templateId,
  });

  const {
    steps,
    selectedStep,
    loading: itemLoading,
  } = useLoadHistoryItemConfig({
    connection,
    projectId,
    historyId: historyItems?.[0]?.historyId,
  });

  const [isOpenHistoryModal, { deactivate: closeHistoryModal, activate: openHistoryModal }] = useToggle(false);

  return (
    <HistoryHandlersContext.Provider
      value={useMemo(() => ({ openHistoryModal, closeHistoryModal }), [closeHistoryModal, openHistoryModal])}
    >
      <HistoryContext.Provider
        value={useMemo(
          () => ({
            historyItems,
            historyModalOpen: isOpenHistoryModal,
            itemsLoading: itemsLoading || itemLoading,
            latestHistoryItem: {
              selectedStep,
              steps,
            },
          }),
          [historyItems, isOpenHistoryModal, itemLoading, itemsLoading, selectedStep, steps],
        )}
      >
        {children}
      </HistoryContext.Provider>
    </HistoryHandlersContext.Provider>
  );
};

export default memo(HistoryProvider);
