import * as React from 'react';
import { cloneDeep } from 'lodash';
import { getStatusesMiddleware } from '../middlewares/status';
import { Status } from '../interfaces/status';

export const useStatuses = (categoryId: string, legendIds?: string[], forceUpdate?: number) => {
  const [statuses, setStatuses] = React.useState<Status[]>([]);

  React.useEffect(() => {
    if (!categoryId) {
      return;
    }
    (async () => {
      const { isSuccess, data } = await getStatusesMiddleware(categoryId, { legendIds });
      if (isSuccess) {
        setStatuses(cloneDeep(data));
      }
    })();
  }, [categoryId, legendIds, forceUpdate]);

  return { statuses, setStatuses };
};
